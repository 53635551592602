import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import isNil from 'lodash/isNil';
import type { FileItem } from '@atlaskit/media-client';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type {
	UploadsStartEventPayload,
	UploadErrorEventPayload,
	UploadEndEventPayload,
	MediaFile,
} from '@atlaskit/media-picker';
import type { UploadConfig } from '../../common/types.tsx';
import type { UseAttachmentsArgs, UseAttachmentsReturn, TemporaryAttachment } from './types.tsx';
import { copyToAttachment, getAttachmentsSize } from './utils.tsx';

const useAttachments = (attachmentArgs: UseAttachmentsArgs): UseAttachmentsReturn => {
	const {
		mediaUploadContext,
		projectId,
		issueId,
		maxAllowedTotalAttachmentsSize,
		onAttachmentSizeValidation,
		attachmentCustomUploadURL,
	} = attachmentArgs;

	if (!mediaUploadContext) {
		throw new Error('mediaUploadContext is null!');
	}
	const [attachments, setAttachments] = useState<TemporaryAttachment[]>([]);
	const [files, setFiles] = useState<MediaFile[]>([]);
	const [isUploading, setUploading] = useState<boolean>(false);
	const [isDefaultValueInitialized, setDefaultValueInitialized] = useState<boolean>(false);
	const stateRef = useRef<MediaFile[]>();
	stateRef.current = files;

	useEffect(() => {
		setFiles(attachments.map((attachment: TemporaryAttachment) => attachment.mediaFile));
		setUploading(attachments.some((attachment: TemporaryAttachment) => attachment.isUploading));
	}, [attachments]);

	const mediaClientConfig: MediaClientConfig = useMemo(
		() => ({
			authProvider: () =>
				Promise.resolve({
					baseUrl: mediaUploadContext.serviceHost,
					clientId: mediaUploadContext.clientId,
					token: mediaUploadContext.token,
				}),
			useSha256ForUploads: true,
		}),
		[mediaUploadContext],
	);

	const uploadConfig: UploadConfig = useMemo(
		() => ({
			uploadParams: {
				collection: mediaUploadContext.collection,
			},
		}),
		[mediaUploadContext.collection],
	);

	const onUploadStart = useCallback(
		(uploadStartPayload: UploadsStartEventPayload) => {
			const currentFiles = stateRef.current ? stateRef.current : [];
			const totalAttachments = [...uploadStartPayload.files, ...currentFiles];
			if (
				maxAllowedTotalAttachmentsSize === undefined ||
				getAttachmentsSize(totalAttachments) <= maxAllowedTotalAttachmentsSize
			) {
				if (onAttachmentSizeValidation) {
					onAttachmentSizeValidation(false);
				}
				setAttachments((currentAttachments) =>
					currentAttachments.concat(
						uploadStartPayload.files.map((mediaFile: MediaFile) => ({
							mediaFile,
							isUploading: true,
						})),
					),
				);
			} else if (onAttachmentSizeValidation) {
				onAttachmentSizeValidation(true);
			}
		},
		[onAttachmentSizeValidation, maxAllowedTotalAttachmentsSize],
	);

	const onUploadError = useCallback((uploadError: UploadErrorEventPayload) => {
		setAttachments((currentAttachments) =>
			currentAttachments.filter(
				(attachment: TemporaryAttachment) => attachment.mediaFile.id !== uploadError.fileId,
			),
		);
	}, []);

	const onUploadEnd = useCallback(
		(uploadEndPayload: UploadEndEventPayload) => {
			(async () => {
				const mediaFile = uploadEndPayload.file;
				if (!isNil(projectId)) {
					await copyToAttachment({
						id: projectId,
						isProjectId: true,
						mediaFile,
						attachmentCustomUploadURL,
					});
				} else if (!isNil(issueId)) {
					await copyToAttachment({
						id: issueId,
						isProjectId: false,
						mediaFile,
						attachmentCustomUploadURL,
					});
				}

				setAttachments((currentAttachments) => {
					const isAttachmentPresentInCurrentAttachments = currentAttachments.find(
						(attachment) => attachment.mediaFile.id === mediaFile.id,
					);

					if (isAttachmentPresentInCurrentAttachments) {
						return currentAttachments
							.filter((attachment: TemporaryAttachment) => attachment.mediaFile.id !== mediaFile.id)
							.concat([
								{
									mediaFile,
									isUploading: false,
								},
							]);
					}
					return currentAttachments;
				});
			})();
		},
		[issueId, projectId, attachmentCustomUploadURL],
	);

	const onRemoveFile = (fileToBeRemoved: FileItem) => {
		setAttachments((currentAttachments) =>
			currentAttachments.filter(
				(attachment: TemporaryAttachment) => attachment.mediaFile.id !== fileToBeRemoved.details.id,
			),
		);
		if (onAttachmentSizeValidation) {
			onAttachmentSizeValidation(false);
		}
	};

	return {
		files,
		setAttachments,
		onUploadStart,
		onUploadError,
		mediaClientConfig,
		uploadConfig,
		onRemoveFile,
		onUploadEnd,
		isUploading,
		isDefaultValueInitialized,
		setDefaultValueInitialized,
	};
};

export default useAttachments;
