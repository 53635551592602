import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	forgeFieldErrorTextLabel: {
		id: 'issue-create-commons.common.forge.forge-field-message.forge-field-error-text-label',
		defaultMessage: "We couldn't save the value of this custom field.",
		description: 'Forge field request error message',
	},
	forgeFieldSuccessTextLabel: {
		id: 'issue-create-commons.common.forge.forge-field-message.forge-field-success-text-label',
		defaultMessage: 'Custom field value saved.',
		description: 'Forge field request success message',
	},
	forgeFieldPendingTextLabel: {
		id: 'issue-create-commons.common.forge.forge-field-message.forge-field-pending-text-label',
		defaultMessage: 'Saving custom field value.',
		description: 'Forge field request pending message',
	},
});
