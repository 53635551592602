import React, { useCallback, useState } from 'react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useCreateTeamDialog } from '@atlassian/jira-issue-field-create-team-dialog/src/ui/use-create-team-dialog/index.tsx';
import { AsyncTeamPickerEdit } from '@atlassian/jira-issue-field-team/src/async.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { FieldLoader } from '../../common/ui/field-loader/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import type { Props, AtlassianTeamFieldConfig, FieldValue } from './types.tsx';
import { transformDefaultAtlassianTeam, validateAtlassianTeamsField } from './utils.tsx';

const overrideSelectStyles = {
	// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
	control: (base) => ({
		...base,
		padding: `${token('space.075', '6px')} ${token('space.075', '6px')} ${token(
			'space.075',
			'6px',
		)} 0`,
	}),
} as const;

const stopFormPropagation = (e: React.FormEvent) => {
	e.stopPropagation();
	e.preventDefault();
};

export const AtlassianTeamsField = (props: Props) => {
	const tenantContext = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const siteId = tenantContext.cloudId;

	/**
	 * The following is a hack to force the TeamPickerEdit to close after creating a team.
	 * @atlaskit/smart-user-picker does not provide a way to close the dropdown menu, as it
	 * doesn't expose the select Ref.
	 *  */
	const [hackIsEditable, setHackIsEditable] = useState(true);

	const {
		width,
		fieldProps: { value, onChange, ...fieldProps },
		isEditable = true,
		nonEditableReason,
		isDropdownMenuFixedAndLayered = false,
		autoFocus,
	} = props;

	const onCreate = useCallback(
		(team: TeamValue) => {
			if (team && team?.id !== value?.id) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'createTeamInline succeeded', 'issueCreate', {
					memberCount: team.memberCount,
				});
				/**
				 * The following is a hack to force the TeamPickerEdit to close after creating a team.
				 * @atlaskit/smart-user-picker does not provide a way to close the dropdown menu, as it
				 * doesn't expose the select Ref.
				 *  */
				setHackIsEditable(false);

				// required wait for new created team to be available
				setTimeout(() => {
					onChange(team);
					setHackIsEditable(true);
				}, 1000);
			}
		},
		[createAnalyticsEvent, onChange, value?.id],
	);
	const [createTeamDialog, createTeamDialogTrigger] = useCreateTeamDialog({
		onCreate,
	});

	const getTeamEdit = () => (
		<Placeholder name="team-picker-edit" fallback={<FieldLoader />}>
			<AsyncTeamPickerEdit
				{...fieldProps}
				width={width}
				siteId={siteId}
				autoFocus={autoFocus}
				defaultValue={value}
				// @ts-expect-error - TS2322 - Type '(value: TeamValue) => void' is not assignable to type 'OnChange'.
				onChange={onChange}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				isDisabled={isEditable === false || !hackIsEditable}
				styles={overrideSelectStyles}
				createTeamDialogTrigger={createTeamDialogTrigger}
			/>
		</Placeholder>
	);

	return (
		<FieldContainer width={width}>
			{isEditable === false ? (
				<Tooltip content={nonEditableReason?.message || ''}>{getTeamEdit()}</Tooltip>
			) : (
				getTeamEdit()
			)}

			<ErrorBoundary id="create-issue-field-team-create-dialog">
				{/* Preventing the parent form from submitting */}
				<form name="gic-create-team-dialog-form" onSubmit={stopFormPropagation}>
					{createTeamDialog}
				</form>
			</ErrorBoundary>
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateAtlassianTeamsField,
	transformDefaultValue: transformDefaultAtlassianTeam,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<AtlassianTeamFieldConfig, TeamValue>, any>'.
})<AtlassianTeamFieldConfig, FieldValue>(AtlassianTeamsField);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateAtlassianTeamField, transformAtlassianTeamFieldTypeString } from './utils';
