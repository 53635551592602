import React from 'react';
import { LabelsEdit } from '@atlassian/jira-issue-field-labels/src/ui/edit/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '../../common/utils/select/index.tsx';
import {
	validateLabelField,
	validateForgeLabelField,
} from '../../common/utils/validators/index.tsx';
import type { Props } from './types.tsx';
import { mapDataToLabelOptions } from './utils.tsx';

const LabelsField = (props: Props) => {
	const {
		autoCompleteUrl,
		error,
		width,
		autoFocus = false,
		isDropdownMenuFixedAndLayered = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		fieldProps: { value, onChange, id, isRequired },
		isReadOnly = false,
	} = props;

	return (
		<FieldContainer width={width}>
			<LabelsEdit
				isInvalid={Boolean(error)}
				fetchSuggestionsOnMount={false}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				autoCompleteUrl={autoCompleteUrl}
				onChange={onChange}
				value={value}
				autoFocus={autoFocus}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDisabled={isReadOnly}
				inputId={id}
				isRequired={isRequired}
			/>
		</FieldContainer>
	);
};

export const ForgeLabelsField = withFormField({
	validator: validateForgeLabelField,
	transformDefaultValue: mapDataToLabelOptions,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<LabelsFieldConfig, FieldValue>, any>'.
})<LabelsFieldConfig, FieldValue>(LabelsField);

export default withFormField({
	validator: validateLabelField,
	transformDefaultValue: mapDataToLabelOptions,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<LabelsFieldConfig, FieldValue>, any>'.
})<LabelsFieldConfig, FieldValue>(LabelsField);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateLabelsFields } from './utils';
