import {
	isFieldTypeSupported,
	isProjectScopedFieldType,
} from '../../common/utils/field-config/index.tsx';
import { isWorkIntakeRollout1EnabledNoExp } from '../../common/utils/work-intake-rollout-1-experiment/index.tsx';
import type { FieldsMeta } from '../../services/field-meta/types.tsx';
import type { JiraBusinessFormWithFieldData } from '../../services/get-form/types.tsx';

type GetFormValidationDataParams = {
	formData?: JiraBusinessFormWithFieldData;
	fieldMetaData?: FieldsMeta;
	projectType?: string;
};

/**
 * Form is valid for submission only when the form contains all required fields
 * And all fields are supported
 */
export const getFormValidationData = ({
	formData,
	fieldMetaData,
	projectType,
}: GetFormValidationDataParams) => {
	if (!formData || !fieldMetaData) {
		return null;
	}

	// required fields in form that are also required in the issue type
	const formRequiredFields = formData.fields.filter(
		(f) => f.isRequired && !!fieldMetaData[f.fieldId],
	);

	// required fields in project
	const requiredFieldIds = Object.keys(fieldMetaData).filter(
		(fieldId) => fieldMetaData[fieldId].required,
	);

	const formContainsAllRequiredFields = formRequiredFields.length === requiredFieldIds.length;

	const isAllRequiredFieldsSupported = requiredFieldIds.every((f) => {
		const fieldType = fieldMetaData[f].schema?.custom || fieldMetaData[f].schema?.system;
		if (!fieldType) {
			return false;
		}

		return isFieldTypeSupported(fieldType, projectType);
	});

	const fieldsRequiredByForm = formData.fields.filter(
		(f) => f.isRequiredByForm && !!fieldMetaData[f.fieldId],
	);

	const hasProjectScopedRequiredFields = fieldsRequiredByForm.some(({ type: fieldType }) => {
		if (!isWorkIntakeRollout1EnabledNoExp(projectType) || !fieldType) {
			return false;
		}

		return isProjectScopedFieldType(fieldType, projectType);
	});

	return {
		isFormSubmissionValid: formContainsAllRequiredFields && isAllRequiredFieldsSupported,
		hasMissingSupportedRequiredFields:
			!formContainsAllRequiredFields && isAllRequiredFieldsSupported,
		hasUnsupportedRequiredFields: !isAllRequiredFieldsSupported,
		hasProjectScopedRequiredFields,
	};
};
