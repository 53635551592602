import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { SprintField } from '@atlassian/jira-issue-create-commons/src/ui/sprint-field/index.tsx';
import type {
	SprintFieldConfig,
	FieldValue,
} from '@atlassian/jira-issue-create-commons/src/ui/sprint-field/types.tsx';
import { transformDefaultSprintValue } from '@atlassian/jira-issue-create-commons/src/ui/sprint-field/utils.tsx';
import { validateRequired } from '../../../utils/field-validators/index.tsx';

export default withFormField({
	transformDefaultValue: transformDefaultSprintValue,
	validator: validateRequired,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<SprintFieldConfig, FieldValue>, any>'.
})<SprintFieldConfig, FieldValue>(SprintField);
