import React from 'react';
import FieldIcon from '@atlaskit/icon/core/field';
import ParentField from '@atlassian/jira-issue-create-commons/src/ui/parent-field/index.tsx';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { selectFieldWriter } from '../writers/index.tsx';

const ParentFieldTypeComponent = (props: ProvidedFieldProps) => {
	if (!props.issueTypeId || !props.canUserViewProject) {
		return null;
	}

	return (
		<ParentField
			{...props}
			selectedProject={{ value: props.projectId }}
			selectedIssueType={{ value: props.issueTypeId }}
			isFieldResponsive
		/>
	);
};

export const ParentFieldTypeConfig: FieldTypeConfig<SelectOption> = {
	icon: FieldIcon,
	type: PARENT_TYPE,
	Component: ParentFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};
