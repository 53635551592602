import React from 'react';
import TextArea from '@atlaskit/textarea';
import type { Props } from './types.tsx';

const TextAreaEdit = (props: Props) => {
	const {
		isRequired = false,
		isInvalid,
		value,
		placeholder = '',
		onBlur,
		onFocus,
		onChange,
		minimumRows = 3,
		autoFocus = false,
		isDisabled = false,
		...fieldProps
	} = props;
	return (
		<TextArea
			{...fieldProps}
			isRequired={isRequired}
			placeholder={placeholder}
			value={value}
			autoFocus={autoFocus}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={onChange}
			isInvalid={isInvalid}
			minimumRows={minimumRows}
			isDisabled={isDisabled}
		/>
	);
};

export default TextAreaEdit;
