import React from 'react';
import UserPicker from '@atlassian/jira-base-user-picker/src/main.tsx';
import { defaultUserPickerStyles } from '@atlassian/jira-base-user-picker/src/styled.tsx';
import type { Props } from './types.tsx';

const UserPickerEdit = (props: Props) => {
	const {
		fieldId,
		appearance,
		width,
		onChange,
		onSelection,
		value,
		autoFocus = false,
		placeholder = '',
		isDisabled = false,
		autoCompleteUrl,
		isMulti,
		fetchSuggestions,
		emptyOption,
		onDataLoaded,
		onDataRequest,
		onCancel,
		isDropdownVisibleByDefault,
		isClearable,
		isDropdownMenuFixedAndLayered = false,
		onCloseMenuOnScroll,
		inputId,
		isBulkEdit = false,
		...fieldProps
	} = props;

	const extraProps = () => {
		if (isDropdownMenuFixedAndLayered) {
			return {
				menuPosition: 'fixed',
				styles: defaultUserPickerStyles,
				onCloseMenuOnScroll,
			} as const;
		}

		return { onCloseMenuOnScroll };
	};

	return (
		<UserPicker
			{...fieldProps}
			fieldId={fieldId}
			inputId={inputId}
			appearance={appearance}
			width={width}
			onChange={onChange}
			value={value}
			autoFocus={autoFocus}
			placeholder={placeholder}
			isDisabled={isDisabled}
			isMulti={isMulti}
			autoCompleteUrl={autoCompleteUrl}
			fetchSuggestions={fetchSuggestions}
			emptyOption={emptyOption}
			onSelection={onSelection}
			onDataLoaded={onDataLoaded}
			onDataRequest={onDataRequest}
			onCancel={onCancel}
			isDropdownVisibleByDefault={isDropdownVisibleByDefault}
			isClearable={isClearable}
			isBulkEdit={isBulkEdit}
			{...extraProps()}
		/>
	);
};

export default UserPickerEdit;
