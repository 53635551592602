import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { StyledFieldSkeleton } from '../styled-common/styled.tsx';
import type { Props } from './types.tsx';

const FieldSkeleton = () => (
	<div
		// eslint-disable-next-line jira/integration/enforce-data-testid-usage
		data-test-id="issue-create-commons.common.ui.loader.skeleton"
		data-testid="issue-create-commons.common.ui.loader.skeleton"
	>
		<Label />
		<StyledFieldSkeleton />
		<Description />
	</div>
);

const IssueCreateLoader = ({ skeletonCount }: Props) => (
	<>
		{Array.from({ length: skeletonCount }, (_, index) => (
			<FieldSkeleton key={`field-skeleton-${index}`} />
		))}
	</>
);

export { FieldSkeleton };
export default IssueCreateLoader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled(Skeleton)({
	height: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 15}px`,
	marginTop: token('space.250', '20px'),
	marginBottom: token('space.075', '6px'),
});

Label.displayName = 'LabelSkeleton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled(Skeleton)({
	height: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 37.5}px`,
});

Description.displayName = 'DescriptionSkeleton';
