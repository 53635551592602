import { R200, Y200, G200, B200, T200, P200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const FORM_COLORS = [R200, Y200, G200, B200, T200, P200] as const;

export type FormColor = (typeof FORM_COLORS)[number];

export const getBannerColor = (index: number): string => FORM_COLORS[index];

export const getRandomBannerColor = (): string =>
	FORM_COLORS[Math.floor(Math.random() * FORM_COLORS.length)];

export const getDesignTokenBannerColor = (colorCode?: string): string => {
	switch (colorCode) {
		case R200:
			return token('color.background.accent.red.subtle', R200);
		case Y200:
			return token('color.background.accent.yellow.subtle', Y200);
		case G200:
			return token('color.background.accent.green.subtle', G200);
		case T200:
			return token('color.background.accent.teal.subtle', T200);
		case P200:
			return token('color.background.accent.purple.subtle', P200);
		case B200:
		default:
			return token('color.background.accent.blue.subtle', B200);
	}
};
