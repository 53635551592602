import type { EnvironmentType } from '@atlassian/forge-ui-types';

const DEV_PREFIX = '(DEVELOPMENT) ';
const STG_PREFIX = '(STAGING) ';

/**
 * Extracts the environment type from a custom field description.
 * @see https://hello.atlassian.net/wiki/spaces/Ditto/pages/2910408874
 * @param descriptionWithEnv custom field description, which optionally contains an environment type prefix
 * @returns the extracted environment type and description with removed prefix
 */
export const extractEnvFromFieldDescription = (
	descriptionWithEnv = '',
): { environment: EnvironmentType; description: string } => {
	if (descriptionWithEnv?.startsWith(DEV_PREFIX)) {
		return {
			environment: 'DEVELOPMENT',
			description: descriptionWithEnv.replace(DEV_PREFIX, ''),
		};
	}

	if (descriptionWithEnv?.startsWith(STG_PREFIX)) {
		return {
			environment: 'STAGING',
			description: descriptionWithEnv.replace(STG_PREFIX, ''),
		};
	}

	return { environment: 'PRODUCTION', description: descriptionWithEnv ?? '' };
};
