import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncTeamPickerEditEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jira-issue-atlassian-team-picker-edit-entrypoint" */ './src/ui/edit-mode'
		).then((module) => module.TeamPickerEditEntryPointContent),
	),
	getPreloadProps: () => ({}),
});
