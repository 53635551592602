import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { Text, Inline, xcss, Flex } from '@atlaskit/primitives';
import { N10, N40, N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const TextFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return <TextFieldWrapper>{formatMessage(messages.fieldPlaceholder)}</TextFieldWrapper>;
};

export const DescriptionFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return <DescriptionWrapper>{formatMessage(messages.fieldPlaceholder)}</DescriptionWrapper>;
};

export const AttachmentFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<AttachmentWrapper>
			<Inline space="space.100" alignBlock="center">
				<UploadIcon label="upload" />
				<Text as="span" color="color.text.subtlest">
					{formatMessage(messages.attachmentFieldPlaceholder)}
				</Text>
			</Inline>
		</AttachmentWrapper>
	);
};

export const AssigneeFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<TextFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Inline space="space.100" alignBlock="center">
					<UserAvatarCircleIcon label="avatar" />
					<Text as="span" color="color.text.subtlest">
						{formatMessage(messages.assigneeFieldPlaceholder)}
					</Text>
				</Inline>
			</Flex>
		</TextFieldWrapper>
	);
};

export const DropdownFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<WithIconAfterFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Text as="span" color="color.text.subtlest">
					{formatMessage(messages.dropdownFieldPlaceholder)}
				</Text>
			</Flex>
			<ChevronDownIcon label="openDropdown" />
		</WithIconAfterFieldWrapper>
	);
};

export const DateFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<WithIconAfterFieldWrapper>
			<Flex xcss={fieldHeight} alignItems="center">
				<Text as="span" color="color.text.subtlest">
					{formatMessage(messages.dateFieldPlaceholder)}
				</Text>
			</Flex>
			<CalendarIcon label="calendar" />
		</WithIconAfterFieldWrapper>
	);
};

export const DateTimeFieldPlaceholder = () => {
	const { formatMessage } = useIntl();
	return (
		<SplitHalfFieldWrapper>
			<Inline alignBlock="center" xcss={fieldHeight}>
				<Text as="span" color="color.text.subtlest">
					{formatMessage(messages.dateFieldPlaceholder)}
				</Text>
			</Inline>
			<Inline alignBlock="center" xcss={fieldHeight}>
				<Text as="span" color="color.text.subtlest">
					{formatMessage(messages.timeFieldPlaceholder)}
				</Text>
			</Inline>
		</SplitHalfFieldWrapper>
	);
};

type NotSupportedFieldPlaceholderProps =
	| {
			isRequired?: false;
			onRemoveFieldClick?: never;
	  }
	| {
			isRequired: boolean;
			onRemoveFieldClick: () => void;
	  };

export const NotSupportedFieldPlaceholder = ({
	isRequired,
	onRemoveFieldClick,
}: NotSupportedFieldPlaceholderProps) => {
	const { formatMessage } = useIntl();

	return (
		<NotSupportedFieldWrapper>
			<Inline space="space.100" alignBlock="center">
				<ErrorIcon label="error" />
				<Text as="span" color="color.text.subtlest">
					{isRequired ? (
						<FormattedMessage
							{...messages.notSupportedRequiredFieldPlaceholder}
							values={{
								contactAdmin: (
									<a href="/secure/ContactAdministrators!default.jspa">
										{formatMessage(messages.contactAdmin)}
									</a>
								),
							}}
						/>
					) : (
						<FormattedMessage
							{...messages.notSupportedOptionalFieldPlaceholder}
							values={{
								clickHere: (
									<ButtonWrapper>
										<Button appearance="link" onClick={onRemoveFieldClick}>
											{formatMessage(messages.clickHere)}
										</Button>
									</ButtonWrapper>
								),
							}}
						/>
					)}
				</Text>
			</Inline>
		</NotSupportedFieldWrapper>
	);
};

const fieldHeight = xcss({
	height: '2.25rem',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldBase = styled.div({
	borderRadius: '4px',
	border: `1px solid ${token('color.border.input', N40)}`,
	background: token('color.background.disabled', N10),
	color: token('color.text.subtlest', N70),
	padding: `0 ${token('space.100', '8px')}`,
	lineHeight: '36px',
});

/**
 * Expect icon in the content as the first element
 * Vertical align the icon and gives space between icon and the content
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconBeforeBase = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginLeft: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldBaseWithIconBefore = styled(FieldBase)({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> * + *': {
		marginLeft: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextFieldWrapper = styled(FieldBaseWithIconBefore)({
	height: '36px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionWrapper = styled(FieldBase)({
	height: '56px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentWrapper = styled(FieldBaseWithIconBefore)({
	height: '36px',
	background: 'none',
	borderStyle: 'dashed',
	justifyContent: 'center',
});

/**
 * Expect an icon as the last child, so it will be placed in the very right
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WithIconAfterFieldWrapper = styled(FieldBase)({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :last-child': {
		marginLeft: 'auto',
	},
});

/**
 * Expect the field contains 2 item where both will take 50% of the width
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SplitHalfFieldWrapper = styled(FieldBase)({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		flexGrow: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotSupportedFieldWrapper = styled(IconBeforeBase)({
	color: token('color.text.subtlest', N70),
	width: '100%',
	lineHeight: '20px',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		padding: 0,
		lineHeight: 'inherit',
		height: '22px',
	},
});
