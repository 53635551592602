import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type { JiraAtlassianTeamFieldInput, JiraAtlassianTeam } from './types.tsx';

export const mutateAtlassianTeamField = (
	selectedTeam: TeamValue,
	fieldId: string,
): JiraAtlassianTeamFieldInput =>
	selectedTeam
		? {
				fieldId,
				mutationGroup: MUTATION_GROUP,
				team: {
					teamId: selectedTeam.id,
				},
			}
		: null;

export const transformDefaultAtlassianTeam = (team: JiraAtlassianTeam): TeamValue => ({
	name: team.name,
	id: team.teamId,
	avatarUrl: team?.avatar?.small,
});

export const transformAtlassianTeamFieldTypeString = (team: TeamValue): string | null => {
	if (team && team.id) {
		return team.id;
	}
	return null;
};

export const validateAtlassianTeamsField = (
	value?: TeamValue | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	isRequired: boolean,
): string | undefined => {
	if (isRequired) {
		if (!value) {
			return REQUIRED;
		}
	}
	return undefined;
};
