import React, {
	createContext,
	useMemo,
	useState,
	Component,
	useContext,
	type ReactNode,
} from 'react';

const ErrorBoundaryBypassApiContext = createContext<null | {
	swallowError: (error: Error) => void;
}>(null);
const ErrorBoundaryBypassErrorContext = createContext<null | Error>(null);

export const ErrorBoundaryBypassProvider = ({ children }: { children: ReactNode }) => {
	const [bypassedError, setBypassedError] = useState<Error | null>(null);

	const api = useMemo(
		() => ({
			// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
			swallowError: (error) => {
				setBypassedError(error);
			},
		}),
		[],
	);

	return (
		<ErrorBoundaryBypassErrorContext.Provider value={bypassedError}>
			<ErrorBoundaryBypassApiContext.Provider value={api}>
				{children}
			</ErrorBoundaryBypassApiContext.Provider>
		</ErrorBoundaryBypassErrorContext.Provider>
	);
};

export class ErrorBoundaryBypassInput extends Component<
	{
		children: ReactNode;
	},
	{
		hasError: boolean;
	}
> {
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	state = { hasError: false };

	componentDidCatch(error: Error) {
		if (this.context !== null) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.context.swallowError(error);
		}
	}

	static contextType = ErrorBoundaryBypassApiContext;

	render() {
		if (this.state.hasError) {
			return null;
		}
		return this.props.children;
	}
}

export const ErrorBoundaryBypassOutput = () => {
	const bypassedError = useContext(ErrorBoundaryBypassErrorContext);

	if (bypassedError !== null) {
		throw bypassedError;
	}

	return null;
};
