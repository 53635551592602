import React from 'react';
import ComponentIcon from '@atlaskit/icon/core/component';
import type { AffectedServiceOption } from '@atlassian/jira-issue-field-affected-services/src/common/types.tsx';
import { SERVICE_ENTITY_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import AffectedServicesField from '../../../ui/fields/affected-services-field/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { multiSelectFieldWriter } from '../writers/index.tsx';

const AffectedServicesCustomFieldComponent = (props: ProvidedFieldProps) => {
	// strip off any query strings from URL as the field component also adds them in
	const autoCompleteUrl = props.autoCompleteUrl?.split('?')[0];

	return <AffectedServicesField {...props} autoCompleteUrl={autoCompleteUrl} />;
};

export const AffectedServicesCustomFieldTypeConfig: FieldTypeConfig<AffectedServiceOption[]> = {
	icon: ComponentIcon,
	type: SERVICE_ENTITY_CF_TYPE,
	Component: AffectedServicesCustomFieldComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multiSelectFieldWriter,
};
