import keyBy from 'lodash/keyBy';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import {
	FORM_LABEL,
	getFieldTypeConfig,
	getFormLabelPrefix,
	getUniqueFormLabel,
} from '../../common/utils/field-config/index.tsx';
import { toLabelsField } from '../../common/utils/field-config/writers/index.tsx';
import type { JiraIssueFieldsInputWritable } from '../../common/utils/field-config/writers/types.tsx';
import type { JiraBusinessFormWithFieldData } from '../get-form/types.tsx';
import type { CreateJiraBusinessIssueByForm } from './__generated_apollo__/CreateJiraBusinessIssueByForm';
import type { FormCreateIssue, FormInputData } from './types.tsx';

export const transformResult = (
	data: CreateJiraBusinessIssueByForm | null | undefined,
): FormCreateIssue | undefined | null => {
	if (!data) {
		return data;
	}

	const issueId = data.createJiraBusinessIssueByForm?.item?.issueId;

	const isIssueIdValid = typeof issueId === 'number';
	const isCreateJiraBusinessIssueSuccessValid =
		typeof data.createJiraBusinessIssueByForm?.success === 'boolean';

	if (!isIssueIdValid || !isCreateJiraBusinessIssueSuccessValid) {
		fireErrorAnalytics({
			meta: {
				id: 'transformFormSubmissionResult',
				packageName: 'jiraBusinessForm',
				teamName: 'jira-werewolves',
			},
			attributes: {
				message: 'Some fields from form submission were not returned properly',
				isIssueIdValid,
				isCreateJiraBusinessIssueSuccessValid,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	if (typeof data.createJiraBusinessIssueByForm?.success !== 'boolean') {
		return undefined;
	}

	return {
		success: data.createJiraBusinessIssueByForm.success,
		issueId,
	};
};

/**
 * Transform the form submission data, (leveraging any other necessary configuration)
 * so it can be massaged into "fields" shape and sent to the endpoint.
 */
type TransformFormDataType = {
	formWithFieldData: JiraBusinessFormWithFieldData;
	formInputData: FormInputData;
	projectType?: string;
};
export const transformFormData = ({
	formInputData,
	formWithFieldData,
	projectType,
}: TransformFormDataType) => {
	const formFieldsById = keyBy(formWithFieldData.fields, (f) => f.fieldId);
	const fields: JiraIssueFieldsInputWritable = {
		users: [],
		labels: [],
		versions: [],
		stringFields: [],
		adfFields: [],
		multipleSelectFields: [],
		singleGroupPickerFields: [],
		multipleGroupPickerFields: [],
		multipleSelectUserPickerFields: [],
	};

	Object.entries(formInputData).forEach(([key, value]) => {
		if (value === undefined || value === null) {
			return;
		}

		const fieldData = formFieldsById[key];
		if (!fieldData) {
			return;
		}

		const { type } = fieldData;

		const fieldConfig = getFieldTypeConfig(type, projectType);
		if (!fieldConfig) {
			fireErrorAnalytics({
				meta: {
					id: 'transformFormSubmissionResult',
					packageName: 'jiraBusinessForm',
					teamName: 'jira-werewolves',
				},
				attributes: {
					message: `"${type}" was unexpectedly missing in form config in form submission`,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			return;
		}

		fieldConfig.writer(key, value, fields);
	});

	// If a label doesn't exist, add it.
	const labelsField = fields.labels.find((f) => f.fieldId === 'labels');
	const formLabelPrefix = fg('jsw_forms_rollout') ? getFormLabelPrefix(projectType) : FORM_LABEL;
	const uniqueFormLabel = getUniqueFormLabel(formLabelPrefix, formWithFieldData.formId);

	// NOTE: We don't want this check to fire exposure - that should happen in the form directory view
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jwm_business_forms_issue_view');

	if (labelsField) {
		if (!labelsField.values.values.includes(formLabelPrefix)) {
			labelsField.values.values.push(formLabelPrefix);
		}
		if (expConfig.get('isFormsIssueViewEnabled', false)) {
			if (!labelsField.values.values.includes(uniqueFormLabel)) {
				labelsField.values.values.push(uniqueFormLabel);
			}
		}
	} else {
		const labelsToAdd = [formLabelPrefix];
		if (expConfig.get('isFormsIssueViewEnabled', false)) {
			labelsToAdd.push(uniqueFormLabel);
		}
		fields.labels.push(toLabelsField('labels', labelsToAdd));
	}

	return fields;
};

export const transformErrorMessage = (
	err: MutationError,
	formWithFieldData: JiraBusinessFormWithFieldData | undefined,
) => {
	const fieldWithError = formWithFieldData?.fields?.find(
		(field) => field.fieldId === err?.extensions?.fieldId,
	);
	const errorMessage = fieldWithError?.alias
		? err?.message.replace(fieldWithError.name, fieldWithError.alias)
		: err?.message;
	return errorMessage;
};
