import {
	FORGE_FIELD_TYPE_PREFIX,
	PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX,
	LEGACY_FORGE_FIELD_TYPE_PREFIX,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';

/**
 * Tests if GIC `fieldType` is a Forge Custom Field.
 *
 * @param fieldType field type
 * @returns true if Forge Custom Field type
 *
 * Note: normally only Gira uses Forge legacy prefix "com.atlassian.forge:ari:cloud:ecosystem::extension/".
 * In most endpoints (incl. AGG) this should be "ari:cloud:ecosystem::extension/".
 *
 */
export const isForgeCustomField = (fieldType: string | undefined): boolean =>
	typeof fieldType === 'string' &&
	(fieldType.startsWith(LEGACY_FORGE_FIELD_TYPE_PREFIX) ||
		fieldType.startsWith(FORGE_FIELD_TYPE_PREFIX));

/**
 * Tests if GIC `fieldType` is a legacy value returned by GIRA
 * @param fieldType field type
 * @returns true if legacy value
 */
export const isForgeLegacyFieldType = (fieldType: string): boolean =>
	fieldType.startsWith(LEGACY_FORGE_FIELD_TYPE_PREFIX);

export const getExtensionIdFromLegacyFieldType = (fieldType: string): string | null =>
	fieldType.split(PARTIAL_LEGACY_FORGE_FIELD_TYPE_PREFIX)[1] || null;

export type FieldsType = {
	[key: string]: { fieldType: string };
};

export const getForgeFields = (fields: FieldsType) =>
	Object.values(fields).filter((field) => isForgeCustomField(field.fieldType));

// this function is used for blurring forge fields
export const focusFormButton = (
	formSelector: string,
	target: Document | HTMLElement = document,
) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const button = target.querySelector(
		`button[form="${formSelector}"][type="submit"]`,
	) as HTMLElement | null;
	if (button != null) button.focus();
};
