import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import CustomTeamEdit from '@atlassian/jira-issue-field-custom-team/src/ui/edit/main.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '../../common/utils/select/index.tsx';
import { validateCustomTeamsField } from '../../common/utils/validators/index.tsx';
import type { Props, CustomTeamFieldConfig, FieldValue } from './types.tsx';
import { transformDefaultTeam } from './utils.tsx';

export const CustomTeamField = (props: Props) => {
	const {
		width,
		fieldProps: { value, onChange },
		fieldId,
		error,
		autoCompleteUrl = '/rest/teams/1.0/teams/find',
		isEditable = true,
		nonEditableReason,
		// @ts-expect-error - TS2339 - Property 'onCloseMenuOnScroll' does not exist on type 'Props'.
		onCloseMenuOnScroll = closeMenuOnScroll,
		// @ts-expect-error - TS2339 - Property 'isDropdownMenuFixedAndLayered' does not exist on type 'Props'.
		isDropdownMenuFixedAndLayered = false,
		ariaLabelledBy,
	} = props;

	const getTeamEdit = () => (
		<CustomTeamEdit
			autoCompleteUrl={autoCompleteUrl}
			onChange={onChange}
			value={value}
			fieldId={fieldId}
			isInvalid={Boolean(error)}
			isDisabled={!isEditable}
			isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
			onCloseMenuOnScroll={onCloseMenuOnScroll}
			fetchSuggestionsOnFocus
			ariaLabelledBy={ariaLabelledBy}
		/>
	);

	return (
		<FieldContainer width={width}>
			{isEditable === false ? (
				<Tooltip content={nonEditableReason?.message || ''}>{getTeamEdit()}</Tooltip>
			) : (
				getTeamEdit()
			)}
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateCustomTeamsField,
	transformDefaultValue: transformDefaultTeam,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<CustomTeamFieldConfig, TeamFieldType>, any>'.
})<CustomTeamFieldConfig, FieldValue>(CustomTeamField);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateTeamField } from './utils';
