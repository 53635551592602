import { useMutation } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	FORM_VALIDATION_ERROR,
	SUBMIT_FORM_EXPERIENCE,
	PACKAGE_NAME,
} from '../../common/constants.tsx';
import { useWorkIntakeRollout1AnalyticsAttrs } from '../../common/utils/work-intake-rollout-1-experiment/index.tsx';
import type { JiraBusinessFormWithFieldData } from '../get-form/types.tsx';
import type {
	CreateJiraBusinessIssueByForm,
	CreateJiraBusinessIssueByFormVariables,
} from './__generated_apollo__/CreateJiraBusinessIssueByForm';
import { REQUIRED_FIELD_MISSING_ERROR, FIELD_VALIDATION_FAILED } from './constants.tsx';
import { FORM_CREATE_ISSUE_MUTATION } from './gql.tsx';
import type { FormInputData } from './types.tsx';
import { transformFormData, transformErrorMessage, transformResult } from './utils.tsx';

const QUERY_NAME = 'CreateJiraBusinessIssueForm';

const logMissing = (name: string) => {
	log.safeErrorWithoutCustomerData(
		PACKAGE_NAME,
		`"${name}" was unexpectedly null or undefined for form submission`,
	);
};

/**
 * This is used for Form submission
 * A simple useCreateIssue service that does not require any optimistic update nor updating any cache
 */

export const useCreateIssue = (
	formWithFieldData?: JiraBusinessFormWithFieldData,
	onComplete?: () => void,
	projectType?: string,
) => {
	const startExperience = useExperienceStart(SUBMIT_FORM_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(SUBMIT_FORM_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(SUBMIT_FORM_EXPERIENCE);
	const extraAttributes = useWorkIntakeRollout1AnalyticsAttrs();

	const doFireErrorAnalytics = (error?: ApolloError | MutationError[]) => {
		fireErrorAnalytics({
			meta: {
				id: QUERY_NAME,
				packageName: 'jiraBusinessForm',
				teamName: 'jira-werewolves',
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: Array.isArray(error) && error.length > 0 ? error[0] : (error as ApolloError),
			sendToPrivacyUnsafeSplunk: true,
		});
	};

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [createIssue, { data, loading, error }] = useMutation<
		CreateJiraBusinessIssueByForm,
		CreateJiraBusinessIssueByFormVariables
	>(FORM_CREATE_ISSUE_MUTATION, {
		onError: (apolloError) => {
			doFireErrorAnalytics(apolloError);
			markExperienceFailed('create issue service - mutation onError', apolloError, extraAttributes);
			log.safeErrorWithoutCustomerData(
				PACKAGE_NAME,
				'ApolloError: Failed to create issue using form',
			);
		},
		onCompleted: (responseData) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), `${QUERY_NAME} succeeded`, {});
			if (responseData.createJiraBusinessIssueByForm?.errors) {
				const errors = responseData.createJiraBusinessIssueByForm.errors
					.map((e) => new MutationError(e))
					.filter(
						(e) =>
							e.message !== FORM_VALIDATION_ERROR.ATTACHMENT_ERROR &&
							e.extensions?.errorType !== REQUIRED_FIELD_MISSING_ERROR &&
							e.extensions?.errorType !== FIELD_VALIDATION_FAILED,
					);
				if (errors.length > 0) {
					doFireErrorAnalytics(errors);
					const mutationError = errors.find((e) => e.message);
					if (mutationError)
						markExperienceFailed(
							'Create issue service mutation failed',
							new MutationError(mutationError),
							{
								errorType: mutationError.extensions?.errorType || '',
								statusCode: mutationError.extensions?.statusCode || '',
								...extraAttributes,
							},
						);
				}
				return;
			}
			if (responseData.createJiraBusinessIssueByForm?.item?.issueId) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'issue created',
					String(responseData.createJiraBusinessIssueByForm.item.issueId),
				);
				onComplete?.();
			}
			markExperienceSuccess(extraAttributes);
		},
	});

	/**
	 * This function will create an issue with specified fields
	 * It will always resolve the promise even if there are any errors (apollo error, bad request)
	 * To know whether it's successful or not, use the `data` and `error` prop returned by the hook
	 */
	const create = async (formInputData: FormInputData) => {
		const projectId: number | undefined = formWithFieldData?.projectId;
		const issueTypeId: string | undefined = formWithFieldData?.issueType?.id;
		const formId: string | undefined = formWithFieldData?.formId;

		startExperience();

		if (typeof formInputData !== 'object') {
			logMissing('formInputData');
			return;
		}
		if (typeof formWithFieldData !== 'object') {
			logMissing('formWithFieldData');
			return;
		}
		if (typeof projectId !== 'number') {
			logMissing('projectId');
			return;
		}
		if (typeof issueTypeId !== 'string') {
			logMissing('issueTypeId');
			return;
		}
		if (typeof formId !== 'string') {
			logMissing('formId');
			return;
		}

		const fields = fg('jsw_forms_rollout')
			? transformFormData({
					formInputData,
					formWithFieldData,
					projectType,
				})
			: transformFormData({
					formInputData,
					formWithFieldData,
				});

		await createIssue({
			variables: {
				input: {
					formId,
					fields,
				},
			},
			// @ts-expect-error - TS2345 - Argument of type '{ variables: { input: { projectId: string; issueType: string; fields: JiraIssueFieldsInput; }; }; onError: () => void; }' is not assignable to parameter of type 'MutationFunctionOptions<CreateJiraBusinessIssueForm, CreateJiraBusinessIssueFormVariables>'.
			// onError is needed to make sure the Promise always resolve instead of throwing Promise rejected error
			onError: () => {
				log.safeErrorWithoutCustomerData(
					PACKAGE_NAME,
					'ApolloError: Failed to create issue using form',
				);
			},
		});
	};

	// find error that has message
	const mutationBadRequestError = data?.createJiraBusinessIssueByForm?.errors
		?.map((e) => new MutationError(e))
		?.find((e: MutationError) => e.message);

	return {
		error:
			// if there are any errors from Apollo, surface that first
			// otherwise if there are errors from bad request, use that
			error || mutationBadRequestError,
		errorMessage: mutationBadRequestError
			? transformErrorMessage(mutationBadRequestError, formWithFieldData)
			: null,
		data: transformResult(data),
		loading,
		create,
	};
};
