import React from 'react';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { AsyncRichTextEditView } from '@atlassian/jira-issue-field-rich-text/src/async.tsx';
import type { DescriptionEdit as DescriptionEditType } from '@atlassian/jira-issue-field-rich-text/src/ui/edit/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

export const DescriptionEdit: typeof DescriptionEditType = (props) => (
	<Placeholder name="smart-card-provider" fallback={<Skeleton height="150px" width="auto" />}>
		<SmartCardProvider>
			<AsyncRichTextEditView {...props} />
		</SmartCardProvider>
	</Placeholder>
);
