import isString from 'lodash/isString';

// copied from src/packages/issue-create/issue-create-commons/src/common/utils/transform-to-object/index.tsx
export const transformJSONStringToObject = (value: string | unknown) => {
	try {
		if (isString(value) && JSON.parse(value)) {
			return JSON.parse(value);
		}
	} catch (e) {
		return value;
	}
	return value;
};
