import { AUTOMATIC_ID } from '@atlassian/jira-issue-field-assignee/src/common/constants.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { SINGLE_USERPICKER_MUTATION_GROUP } from '../../common/ui/fields/base-fields/user-picker-field/constants.tsx';
import type { JiraSingleSelectUserPickerFieldInput } from '../../common/ui/fields/base-fields/user-picker-field/types.tsx';
import type { DefaultUser } from './types.tsx';

export const transformDefaultAssigneeValue = (
	value: DefaultUser, // if number, we can assume it's the automatic option, now convert -1 to "__AUTOMATIC__" because we are using type string for 'accountId' everywhere
): UserValue => ({
	accountId: (value.accountId === '-1' ? AUTOMATIC_ID : value.accountId) || '',
	displayName: value.name || '',
	avatarUrls:
		value.picture !== undefined
			? {
					'16x16': value.picture,
					'24x24': value.picture,
					'32x32': value.picture,
					'48x48': value.picture,
				}
			: {},
});

export const mapUserToAssigneeValue = (value: UserValue) => {
	if (value === null) {
		return null;
	}
	if (value.accountId === AUTOMATIC_ID) {
		return undefined;
	}
	return {
		id: value.accountId,
	};
};

export const mutateAssigneeUserPicker = (
	selectedUser: UserValue,
	fieldId: string,
): JiraSingleSelectUserPickerFieldInput | null => {
	if (selectedUser === null || selectedUser.accountId === null) {
		return null;
	}
	return {
		fieldId,
		mutationGroup: SINGLE_USERPICKER_MUTATION_GROUP,
		user: {
			accountId: selectedUser.accountId,
		},
	};
};
