import type {
	Group,
	UserPickerOption,
} from '@atlassian/jira-issue-field-group-picker/src/common/types.tsx';
import { transformGroupToUserOption } from '@atlassian/jira-issue-field-group-picker/src/common/utils/index.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type { JiraSingleGroupPickerFieldInput } from './types.tsx';

export const transformGroupDefaultValueToUserOption = (
	group: Group | null,
): UserPickerOption | null => {
	if (!group) {
		return null;
	}
	return transformGroupToUserOption(group);
};

export const mutateSingleGroupPickerField = (
	selectedGroup: UserPickerOption,
	fieldId: string,
): JiraSingleGroupPickerFieldInput => ({
	fieldId,
	group: { groupName: selectedGroup.name },
	mutationGroup: MUTATION_GROUP,
});
