// keeping it here instead of jira-issue-create to avoid cyclic dependencies.
export const ISSUE_CREATE_MODAL_BODY_SELECTOR = 'issue-create.ui.modal.modal-body';

const MENU_CLOSE_SELECTORS = [ISSUE_CREATE_MODAL_BODY_SELECTOR];
export const closeMenuOnScroll = (e: Event) => {
	// @ts-expect-error - TS18047 -  'e.target' is possibly 'null'.
	if (MENU_CLOSE_SELECTORS.includes(e.target.id)) {
		return true;
	}
	return false;
};
