import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useFormState } from '@atlaskit/form';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const useMessageCreator = () => {
	const { formatMessage } = useIntl();

	return useCallback(
		(
			prevValues: Record<string, unknown> | undefined,
			newValues: Record<string, unknown> | undefined,
		) => {
			if (!prevValues || !newValues) {
				return '';
			}

			// Then number of keys in form values is a proxy for number of
			// fields on the form
			const fieldCountDiff = Object.keys(prevValues).length - Object.keys(newValues).length;

			if (fieldCountDiff === 0) {
				return '';
			}

			return fieldCountDiff < 0
				? formatMessage(messages.fieldsAdded, { fieldCount: Math.abs(fieldCountDiff) })
				: formatMessage(messages.fieldsRemoved, { fieldCount: Math.abs(fieldCountDiff) });
		},
		[formatMessage],
	);
};

/**
 * Creates screenreader announcements to notify users
 * when fields are added or removed from a form. This
 * occurs as field values change and conditions are met
 * or unmet.
 */
const ScreenreaderAnnouncer = () => {
	const formState = useFormState<Record<string, unknown>>({
		values: true,
	});
	const prevValues = useRef<Record<string, unknown>>();
	const [message, setMessage] = useState('');
	const getMessage = useMessageCreator();

	/**
	 * When multiple fields are added or removed from a form after
	 * a single form update, multiple state updates might be triggered
	 * via useFormState. In order to capture all of these updates as
	 * unified message, we need to debounce the update of the message.
	 *
	 * Without this, for example, instead of:
	 * 	Updated message: "4 fields have been removed below"
	 * We might end up with:
	 * 	Updated message 1: "2 fields have been removed below"
	 *  Updated message 2: "2 fields have been removed below"
	 */
	const debouncedUpdateMessage = useMemo(
		() =>
			debounce((newValues?: Record<string, unknown>) => {
				const newMessage = getMessage(prevValues.current, newValues);
				prevValues.current = newValues;
				setMessage(newMessage);
			}, 200),
		[getMessage],
	);

	useEffect(() => {
		debouncedUpdateMessage(formState?.values);
	}, [debouncedUpdateMessage, formState?.values]);

	return <AnnouncerV2 message={message} />;
};

export default ScreenreaderAnnouncer;
