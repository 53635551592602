import React from 'react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import type { UserOption } from '@atlassian/jira-base-user-picker/src/types.tsx';
import MultiUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/multi-userpicker-field/index.tsx';
import SingleUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/single-userpicker-field/index.tsx';
import { PEOPLE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { multiUserPickerFieldWriter, singleUserPickerFieldWriter } from '../writers/index.tsx';

type PeopleCustomFieldTypeSchemaConfiguration = {
	isMulti: boolean;
};

const PeopleCustomFieldTypeComponent = (
	props: ProvidedFieldProps<PeopleCustomFieldTypeSchemaConfiguration>,
) => {
	if (props.schemaConfiguration.isMulti) {
		return <MultiUserPickerField {...props} />;
	}

	return <SingleUserPickerField {...props} />;
};

export const PeopleCustomFieldType: FieldTypeConfig<
	UserOption | UserOption[],
	PeopleCustomFieldTypeSchemaConfiguration
> = {
	icon: TeamsIcon,
	type: PEOPLE_CF_TYPE,
	Component: PeopleCustomFieldTypeComponent,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: (id, value, fields) =>
		Array.isArray(value)
			? multiUserPickerFieldWriter(id, value, fields)
			: singleUserPickerFieldWriter(id, value, fields),
};
