import React from 'react';
import CheckboxUncheckedIcon from '@atlaskit/icon/core/checkbox-unchecked';
import MultiSelectField from '@atlassian/jira-issue-create-commons/src/ui/multi-select-field/index.tsx';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import { MULTI_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { multiSelectFieldWriter } from '../writers/index.tsx';

const MultiSelectCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<MultiSelectField {...props} defaultValue={undefined} />
);

export const MultiSelectCustomFieldTypeConfig: FieldTypeConfig<SelectOption[]> = {
	icon: CheckboxUncheckedIcon,
	type: MULTI_SELECT_CF_TYPE,
	Component: MultiSelectCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multiSelectFieldWriter,
};
