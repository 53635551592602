import gqlTagGira from 'graphql-tag';

export const FORM_CREATE_ISSUE_MUTATION = gqlTagGira`
mutation CreateJiraBusinessIssueByForm($input: JwmFormCreateIssueInput!) {
  createJiraBusinessIssueByForm(input: $input) {
    item {
      id
      issueId
    }
    success
    errors {
      message
      extensions {
        ... on FieldValidationMutationErrorExtension {
          statusCode
          errorType
          fieldId
        }
      }
    }
  }
}
`;
