import React, { type PropsWithChildren, useContext, createContext, useMemo, useRef } from 'react';

export type ContextValue = {
	setFieldValue: (name: string, value: unknown) => void;
	getFieldValue: (name: string) => unknown;
};

type Props = PropsWithChildren<{
	setFieldValue: (name: string, value: unknown) => void;
	getFormState: () => { values: Record<string, unknown> };
}>;

const Context = createContext<ContextValue | null>(null);

/**
 * This component uses context API to provide referencially stable functions:
 * - setFieldValue
 * - getFieldValue
 *
 * It was primarily created for UI modifications by mkonopka and bcytrowski to provide
 * isolation from unstable @atlaskit/form API used for the purpose
 * of setting/getting any given field's value.
 */
export const FormApiProvider = ({ children, setFieldValue, getFormState }: Props) => {
	const setFieldValueRef = useRef(setFieldValue);
	setFieldValueRef.current = setFieldValue;

	const getFormStateRef = useRef(getFormState);
	getFormStateRef.current = getFormState;

	const value = useMemo<ContextValue>(
		() => ({
			setFieldValue: (...args) => setFieldValueRef.current(...args),
			getFieldValue: (fieldId) => getFormStateRef.current().values[fieldId],
		}),
		[],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useFormApi = () => {
	const context = useContext(Context);

	if (context === null) {
		throw new Error('Missing FormApiProvider');
	}

	return context;
};
