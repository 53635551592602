import React from 'react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import SingleGroupPickerField from '@atlassian/jira-issue-create-commons/src/ui/single-grouppicker-field/index.tsx';
import type { UserPickerOption } from '@atlassian/jira-issue-field-group-picker/src/common/types.tsx';
import { GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { singleGroupPickerFieldWriter } from '../writers/index.tsx';

const GroupCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<SingleGroupPickerField {...props} />
);

export const GroupCustomFieldTypeConfig: FieldTypeConfig<UserPickerOption> = {
	icon: TeamsIcon,
	type: GROUP_CF_TYPE,
	Component: GroupCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: singleGroupPickerFieldWriter,
};
