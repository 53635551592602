import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-field-labels.edit.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-labels.edit.failed-fetch',
		defaultMessage: 'Search failed. Please try again.',
		description: 'Message when fetching suggestions fails',
	},
	placeholder: {
		id: 'issue-field-labels.edit.placeholder',
		defaultMessage: 'Select label',
		description: 'Placeholder text for select component',
	},
	createNewItem: {
		id: 'issue-field-labels.edit.create-new-item',
		defaultMessage: 'New label',
		description: 'Message to show when user is asked to create a new label',
	},
	loading: {
		id: 'issue-field-labels.edit.loading',
		defaultMessage: 'Searching...',
		description: 'Message showed when options are being loaded from the server.',
	},
	recent: {
		id: 'issue-field-labels.edit.recent',
		defaultMessage: 'Recent labels',
		description: 'Header displayed in labels list used to mark recently selected items',
	},
	all: {
		id: 'issue-field-labels.edit.all',
		defaultMessage: 'All labels',
		description: 'Header displayed in labels list used to mark fetched from server items',
	},
});
