import React from 'react';
import InputField, {
	type FieldProps as Props,
} from '../../common/ui/fields/base-fields/input-field/index.tsx';

const TextField = (props: Props) => <InputField type="text" {...props} />;

export default TextField;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateTextField } from './utils';
