import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import type { FieldParentConditionFragment } from '../../../../../../services/__generated_apollo__/FieldParentConditionFragment';

export type ConditionValidator = (fieldValue: SelectOption | SelectOption[] | null) => boolean;

/**
 * As an extra measure of safety we verify that the
 * options being passed through the form conform to
 * expected shape. This is desired as it is not possible
 * to trace the values returned by useFormState back
 * to the exact fields that have been rendered in the
 * form, and the value-types of those fields.
 */
export const isSelectOption = (
	possibleSelectOption: SelectOption | SelectOption[],
): possibleSelectOption is SelectOption => {
	return (
		'value' in possibleSelectOption &&
		(typeof possibleSelectOption.value === 'undefined' ||
			typeof possibleSelectOption.value === 'string')
	);
};

export const isSelectOptionArray = (
	possibleSelectOptionArray: SelectOption | SelectOption[],
): possibleSelectOptionArray is SelectOption[] => {
	if (!Array.isArray(possibleSelectOptionArray)) {
		return false;
	}

	// Confirm that each of the selected values conforms to the expected shape
	if (possibleSelectOptionArray.length) {
		return possibleSelectOptionArray.every(isSelectOption);
	}

	// When empty we assume the array is valid until proven otherwise
	return true;
};

export const makeSelectConditionValidator = (
	condition: FieldParentConditionFragment,
): ConditionValidator => {
	const { fieldOptionIds, operator } = condition;

	return (fieldValue) => {
		if (!fieldOptionIds?.length) {
			return true;
		}

		// Value will be null when user clears
		// the input of a single-select field
		if (fieldValue === null) {
			return false;
		}

		if (isSelectOption(fieldValue)) {
			return fieldOptionIds.includes(fieldValue.value);
		}

		if (isSelectOptionArray(fieldValue)) {
			return operator === 'ALL'
				? fieldOptionIds.every((id) => fieldValue.find(({ value }) => value === id))
				: fieldOptionIds.some((id) => fieldValue.find(({ value }) => value === id));
		}

		// Default to true if the shape of the given
		// field value is non-conforming
		return true;
	};
};
