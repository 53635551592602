import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue-field-components-field.edit.placeholder',
		defaultMessage: 'Select Component',
		description: 'Placeholder text for select component',
	},
	createNewItem: {
		id: 'issue-field-components-field.edit.create-new-item',
		defaultMessage: 'New Component',
		description: 'Message to show when user is asked to create a new component',
	},
});
