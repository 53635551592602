import React from 'react';
import HashtagIcon from '@atlaskit/icon/core/hashtag';
import NumberField from '@atlassian/jira-issue-create-commons/src/ui/number-field/index.tsx';
import { STORY_POINT_ESTIMATE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { TextFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { numberFieldWriter } from '../writers/index.tsx';

const StoryPointEstimateCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<NumberField {...props} />
);

export const StoryPointEstimateCustomFieldTypeConfig: FieldTypeConfig<string> = {
	icon: HashtagIcon,
	type: STORY_POINT_ESTIMATE_CF_TYPE,
	Component: StoryPointEstimateCustomFieldTypeComponent,
	placeholder: <TextFieldPlaceholder />,
	writer: numberFieldWriter,
};
