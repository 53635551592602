import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	modalModeErrorTitle: {
		id: 'issue-create-commons.common.forge.edit.modal-mode-error-title',
		defaultMessage: "We couldn't show this extension",
		description:
			'Title of the error panel shown when an app is using a deprecated package version.',
	},
	modalModeErrorDescription: {
		id: 'issue-create-commons.common.forge.edit.modal-mode-error-description',
		defaultMessage: 'Contact the app vendor to update the app.',
		description:
			'Description of the error panel shown when an app is using a deprecated package version',
	},
});
