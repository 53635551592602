import qs from 'query-string';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	AffectedServiceOption,
	ServiceEntity,
	FetchServicesResponse,
} from '../../common/types.tsx';

export const mapServiceEntitiesToOptions = (services: ServiceEntity[]): AffectedServiceOption[] =>
	services.map(({ id, name }) => ({ label: name, value: id }));

export const mapOptionsToServiceEntities = (options: AffectedServiceOption[]): ServiceEntity[] =>
	options.map(({ label, value }) => ({ id: value, name: label }));

export const fetchAffectedServices = async (
	inputValue: string,
	autoCompleteUrl: string,
): Promise<AffectedServiceOption[]> => {
	const query = qs.stringify({ query: inputValue });
	return performGetRequest(`${autoCompleteUrl}?${query}`)
		.then((response: FetchServicesResponse) => mapServiceEntitiesToOptions(response.services))
		.catch((error) => {
			trackFetchErrors({
				error,
				id: 'fetchAffectedServices',
				packageName: 'jiraIssueFieldAffectedServices',
				logToSentry: !isValidationError(error),
				sendToPrivacyUnsafeSplunk: true,
				attributes: {
					errorMessage: error.message,
				},
			});
			throw error;
		});
};
