import type { UserOption, UserId } from '@atlassian/jira-base-user-picker/src/types.tsx';
import { SINGLE_USERPICKER_MUTATION_GROUP, MULTI_USERPICKER_MUTATION_GROUP } from './constants.tsx';
import type {
	JiraSingleSelectUserPickerFieldInput,
	JiraMultiSelectUserPickerFieldInput,
	JiraUser,
} from './types.tsx';

export const transformJiraUserToUserOption = (userData: JiraUser): UserOption => ({
	id: userData.accountId || '',
	name: userData.name || '',
	avatarUrl: userData.picture || '',
});

export const transformDefaultUserValues = (
	defaultValue: JiraUser | JiraUser[],
): UserOption | UserOption[] => {
	if (Array.isArray(defaultValue)) {
		return defaultValue.map(transformJiraUserToUserOption);
	}
	return transformJiraUserToUserOption(defaultValue);
};

export const mapUserOptionsToIds = (userOption: UserOption | UserOption[]): UserId | UserId[] => {
	if (Array.isArray(userOption)) {
		return userOption.map<UserId>((option) => ({
			id: option.id,
		}));
	}
	return {
		id: userOption.id,
	};
};

export const mapUserOptionsToAccountIds = (
	userOption: UserOption | UserOption[],
):
	| {
			accountId: string;
	  }
	| {
			accountId: string;
	  }[] => {
	if (Array.isArray(userOption)) {
		return userOption.map<{
			accountId: string;
		}>((option) => ({
			accountId: option.id,
		}));
	}
	return {
		accountId: userOption.id,
	};
};

export const mutateSingleUserPicker = (
	selectedUser: UserOption,
	fieldId: string,
): JiraSingleSelectUserPickerFieldInput => ({
	fieldId,
	mutationGroup: SINGLE_USERPICKER_MUTATION_GROUP,
	user: {
		accountId: selectedUser.id,
	},
});

export const mutateMultiUserPicker = (
	selectedUsers: UserOption[],
	fieldId: string,
): JiraMultiSelectUserPickerFieldInput => ({
	fieldId,
	mutationGroup: MULTI_USERPICKER_MUTATION_GROUP,
	users: selectedUsers.map(
		(
			user: UserOption,
		): {
			accountId: string;
		} => ({
			accountId: user.id,
		}),
	),
});
