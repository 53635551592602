import type { ReactNode } from 'react';
import { styled } from '@compiled/react';

type FieldWidth = string | number;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldContainer = styled.div<{
	children?: ReactNode;
	width?: FieldWidth;
	marginTop?: number;
	paddingBottom?: number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width = 350 }) => (typeof width === 'number' ? `${width}px` : width),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ marginTop = 0 }) => `${marginTop}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ paddingBottom = 0 }) => `${paddingBottom}px`,
});
