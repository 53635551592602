import type { ReactNode } from 'react';
import type { FieldParentConditionFragment } from '../../../../services/__generated_apollo__/FieldParentConditionFragment';
import { useConditionValidator } from './utils/index.tsx';

type Props = {
	condition: FieldParentConditionFragment;
	children: ReactNode;
};

const Condition = ({ condition, children }: Props) => {
	const isValid = useConditionValidator(condition);
	return isValid ? children : null;
};

export default Condition;
