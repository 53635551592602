import type {
	OptionExternalData,
	SelectOption,
} from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import {
	mapDataToSelectOptions,
	mapDataToSelectOption,
} from '@atlassian/jira-issue-field-select/src/ui/edit/utils.tsx';

export const transformDefaultSelectValues = (
	defaultValue: OptionExternalData | OptionExternalData[],
): SelectOption | SelectOption[] => {
	if (Array.isArray(defaultValue)) {
		return mapDataToSelectOptions(defaultValue);
	}
	return mapDataToSelectOption(defaultValue);
};

export const onFilterOption = (option: SelectOption, inputValue: string) =>
	option.label.toLowerCase().includes(inputValue.toLowerCase());
