import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Option } from '@atlassian/jira-issue-field-select-base/src/common/types.tsx';
import type { JiraSprint, JiraSprintFieldInput } from './types.tsx';

export const transformDefaultSprintValue = (selectedSprints: JiraSprint[]): Option => {
	const sprint = selectedSprints[0];
	return sprint && { label: sprint.name, value: sprint.sprintId, state: sprint.state };
};

export const mutateJiraSprintField = (
	{ value }: Option,
	fieldId: string,
): JiraSprintFieldInput => ({
	sprintsField: {
		fieldId,
		sprints: [{ sprintId: value.toString() }], // searchable select causes type dilution value: number|string
	},
});

export const getSuggestionsUrl = (autoCompleteUrl: string, query: string, projectKey?: string) => {
	const queryString = `${autoCompleteUrl}${encodeURIComponent(query)}`;
	if (projectKey !== null && projectKey !== undefined) {
		return `${queryString}&projectKey=${encodeURIComponent(projectKey)}`;
	}
	return queryString;
};

export const getSprintSuggestions = async (
	autoCompleteUrl: string,
	query: string,
	projectKey?: string,
) => performGetRequest(getSuggestionsUrl(autoCompleteUrl, query, projectKey));
