import { useState, useEffect, useCallback } from 'react';
import uniq from 'lodash/uniq';
// Number of custom fields
// Field types array
import type { JiraBusinessFormWithFieldDataField } from '../../../services/get-form/types.tsx';

type FieldAnalytics = {
	fieldIds: string[];
	customFieldCount: number;
	fieldCount: number;
};
export const generateFieldAnalytics = (
	fields: JiraBusinessFormWithFieldDataField[],
): FieldAnalytics => {
	const fieldIds = uniq(
		fields.map((field: JiraBusinessFormWithFieldDataField) =>
			field.type.replace('com.atlassian.jira.plugin.system.customfieldtypes:', ''),
		),
	);
	const customFieldCount = fields.filter((field: JiraBusinessFormWithFieldDataField) =>
		field.fieldId.startsWith('customfield_'),
	).length;

	const fieldCount = fields.length;

	return {
		fieldIds,
		customFieldCount,
		fieldCount,
	};
};

export const useIntersectionObserver = (
	onIntersection: () => void,
): ((node: Element | null) => void) => {
	const [ref, setRef] = useState<Element | null>(null);

	const handleObserver: IntersectionObserverCallback = useCallback(
		(entries) => {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					onIntersection();
				}
			});
		},
		[onIntersection],
	);

	useEffect(() => {
		if (
			!ref ||
			typeof window === 'undefined' ||
			(typeof window !== 'undefined' && !window.IntersectionObserver)
		) {
			return undefined;
		}

		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: '20px',
			threshold: 0,
		});

		if (ref) {
			observer.observe(ref);
		}

		return () => {
			observer.disconnect();
		};
	}, [ref, onIntersection, handleObserver]);

	return setRef;
};
