import React from 'react';
import Select from '@atlaskit/select';
import { formatOptionLabel } from '@atlassian/jira-issue-field-select-base/src/ui/format-option-label/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { Props } from './types.tsx';

const PriorityEdit = (props: Props) => {
	const {
		value,
		options,
		onChange,
		onFocus,
		onBlur,
		placeholder = '',
		autoFocus,
		isInvalid,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		isDisabled,
		inputId,
		isRequired,
		classNamePrefix,
		openMenuOnFocus,
		ariaLabel,
		ariaLabelledBy,
	} = props;

	return (
		<Select
			inputId={inputId}
			required={isRequired}
			value={value}
			options={options}
			placeholder={placeholder}
			// @ts-expect-error - TS2322 - Type '(arg1: Option) => void' is not assignable to type '(value: Option | null, action: ActionMeta<Option>) => void'.
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			autoFocus={autoFocus}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			formatOptionLabel={formatOptionLabel}
			isClearable={false}
			hideSelectedOptions
			closeMenuOnScroll={onCloseMenuOnScroll}
			isDisabled={isDisabled}
			classNamePrefix={classNamePrefix}
			openMenuOnFocus={openMenuOnFocus}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
		/>
	);
};

export default PriorityEdit;
