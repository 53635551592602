import React, { useState, useCallback, type ReactNode } from 'react';
import Banner from '@atlaskit/banner';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const ContactYourAdminLink = ({ children }: { children: ReactNode }) => (
	<a href="/jira/contact-administrator">{children}</a>
);

export const ContactAdminBanner = () => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const handleDismissClick = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<Box xcss={bannerContainerStyles}>
			<Banner
				testId="business-form.ui.form-submission.contact-admin-banner.banner"
				appearance="announcement"
			>
				<FormattedI18nMessage
					componentsMapping={{
						link: ContactYourAdminLink,
					}}
					message={formatMessage(messages.needToEdit, {
						linkStart: '{linkStart}',
						linkEnd: '{linkEnd}',
					})}
				/>
			</Banner>
			<Box xcss={buttonContainerStyles}>
				<Button
					label="dismiss"
					appearance="subtle"
					iconBefore={
						<CrossIcon
							size="medium"
							label={formatMessage(messages.dismiss)}
							primaryColor={token('color.text.inverse', 'white')}
						/>
					}
					onClick={handleDismissClick}
				/>
			</Box>
		</Box>
	);
};

const bannerContainerStyles = xcss({
	position: 'relative',
});

const buttonContainerStyles = xcss({
	position: 'absolute',
	top: 'space.100',
	right: 'space.100',
});
