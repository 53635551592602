import React from 'react';
import TagIcon from '@atlaskit/icon/core/tag';
import LabelsField from '@atlassian/jira-issue-create-commons/src/ui/labels-field/index.tsx';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { labelsFieldWriter } from '../writers/index.tsx';

const LabelsFieldTypeComponent = (props: ProvidedFieldProps) => <LabelsField {...props} />;

export const LabelsFieldTypeConfig: FieldTypeConfig<Option[]> = {
	icon: TagIcon,
	type: LABELS_TYPE,
	Component: LabelsFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: labelsFieldWriter,
};
