import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldPlaceholder: {
		id: 'business-form.common.field-placeholders.field-placeholder',
		defaultMessage: 'Answer will be written here',
		description:
			'Placeholder message in text field, indicating that user will be able to fill in the field',
	},
	attachmentFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.attachment-field-placeholder',
		defaultMessage: 'Attachments will be uploaded here',
		description:
			'Placeholder message for attachment field, indicating that user will be able to drag and drop attachment into the field',
	},
	assigneeFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.assignee-field-placeholder',
		defaultMessage: 'Unassigned',
		description:
			'Placeholder message for assignee field, indicating that user will be able to assign user in the field',
	},
	dropdownFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.dropdown-field-placeholder',
		defaultMessage: 'Answer will be selected from dropdown',
		description:
			'Placeholder message in the dropdown field, indicating that the field can be populated by selecting items from the dropdown',
	},
	dateFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.date-field-placeholder',
		defaultMessage: 'Date will be entered here',
		description:
			'Placeholder message in the date field, indicating that the field can be populated by opening the calendar when clicking the field',
	},
	timeFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.time-field-placeholder',
		defaultMessage: 'Time will be entered here',
		description:
			'Placeholder message in the time field, indicating that the field can only be populated by time',
	},
	notSupportedRequiredFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.not-supported-required-field-placeholder',
		defaultMessage:
			'This required field is unsupported, {contactAdmin} to make this field optional',
		description:
			'Message to indicate to this REQUIRED field is not supported in the form, and the way to fix it is by making the field optional',
	},
	contactAdmin: {
		id: 'business-form.common.field-placeholders.contact-admin',
		defaultMessage: 'contact admin',
		description: 'Clickable link that will redirect user to contact admin page',
	},
	notSupportedOptionalFieldPlaceholder: {
		id: 'business-form.common.field-placeholders.not-supported-optional-field-placeholder',
		defaultMessage: 'This field is unsupported, {clickHere} to remove this field. ',
		description:
			'Message to indicate to this OPTIONAL field is not supported in the form, and user will need to remove it',
	},
	clickHere: {
		id: 'business-form.common.field-placeholders.click-here',
		defaultMessage: 'click here',
		description: 'Click here call to action',
	},
});
