import { useMemo } from 'react';
import { useFormState } from '@atlaskit/form';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import type { FieldParentConditionFragment } from '../../../../../services/__generated_apollo__/FieldParentConditionFragment';
import { makeSelectConditionValidator, type ConditionValidator } from './validators/index.tsx';

/**
 * Returns a validator function based on the type of condition
 * supplied, or null when the given condition type in unsupported.
 *
 * The validator function takes in the form field value and returns
 * a boolean indicating whether the condition has been met
 */
export const getConditionValidator = (
	condition: FieldParentConditionFragment,
): ConditionValidator | null => {
	if (condition.__typename === 'JiraFormSelectCondition') {
		return makeSelectConditionValidator(condition);
	}

	return null;
};

/**
 * Takes a condition and returns a boolean indicating whether
 * the condition has been met based on the current form state.
 */
export const useConditionValidator = (condition: FieldParentConditionFragment) => {
	const formState = useFormState<Record<string, SelectOption | SelectOption[] | null | undefined>>({
		values: true,
	});
	const validator = useMemo(() => getConditionValidator(condition), [condition]);

	if (!validator || !formState?.values) {
		return true;
	}

	const fieldValue = formState.values[condition.parentField.fieldId];

	// User has not input anything yet
	if (fieldValue === undefined) {
		return false;
	}

	return validator(fieldValue);
};
