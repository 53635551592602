import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	needToEdit: {
		id: 'business-form.form-submission.contact-admin-banner.need-to-edit',
		defaultMessage: '{linkStart}Contact your admin{linkEnd} to edit this form.',
		description:
			'Text displayed to non-admin users who may have tried to edit a form and instead are viewing its preview state with a link to contact their admin',
	},
	dismiss: {
		id: 'business-form.form-submission.contact-admin-banner.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Label added to button to dismiss banner message',
	},
});
