import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_GROUPS_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createRouterSelector } from '@atlassian/react-resource-router';
import type { RouterState } from '@atlassian/react-resource-router/src/controllers/router-store/types';

const SOFTWARE_ROUTES_GROUPS = [ROUTE_GROUPS_CLASSIC_SOFTWARE, ROUTE_GROUPS_SOFTWARE];

export const getIsSoftwareProject = (state: RouterState) =>
	SOFTWARE_ROUTES_GROUPS.includes(state.route.group ?? '');

export const useIsSoftwareProject = createRouterSelector(getIsSoftwareProject);

/**
 * Allows project type to be safely retrieved
 * even when users do not have access to a given
 * project, e.g. users with no project access
 * visiting the submission view for an OPEN form.
 */
export const useProjectType = () => {
	if (!fg('jsw_forms_rollout')) {
		return undefined;
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isSoftwareProject = useIsSoftwareProject();
	return isSoftwareProject ? SOFTWARE_PROJECT : CORE_PROJECT;
};
