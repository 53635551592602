import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { AffectedServicesField as AffectedServicesFieldInternal } from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field/index.tsx';
import type {
	AffectedServicesFieldConfig,
	FieldValue,
} from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field/types.tsx';
import { transformDefaultAffectedServices } from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field/utils.tsx';
import { validateRequired } from '../../../utils/field-validators/index.tsx';

const AffectedServicesField = withFormField({
	transformDefaultValue: transformDefaultAffectedServices,
	validator: validateRequired,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<AffectedServicesFieldConfig, FieldValue>, any>'.
})<AffectedServicesFieldConfig, FieldValue>(AffectedServicesFieldInternal);

export default AffectedServicesField;
