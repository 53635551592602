// @ts-expect-error - TS2614 - Module '"@atlaskit/user-picker"' has no exported member 'LegacyValue'. Did you mean to use 'import LegacyValue from "@atlaskit/user-picker"' instead?
import type { LegacyValue } from '@atlaskit/user-picker';
import type {
	Group,
	UserPickerOption,
} from '@atlassian/jira-issue-field-group-picker/src/common/types.tsx';
import { transformGroupToUserOption } from '@atlassian/jira-issue-field-group-picker/src/common/utils/index.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type { JiraMultipleGroupPickerFieldInput } from './types.tsx';

export const transformGroupDefaultValueToUserOption = (
	group: LegacyValue<Group>,
): LegacyValue<UserPickerOption> => {
	if (!group) {
		return null;
	}
	if (Array.isArray(group)) {
		return group.map((groupItem: Group): UserPickerOption => transformGroupToUserOption(groupItem));
	}
	return transformGroupToUserOption(group);
};

export const mutateMultipleGroupPickerField = (
	selectedGroups: UserPickerOption[],
	fieldId: string,
): JiraMultipleGroupPickerFieldInput => ({
	fieldId,
	groups: selectedGroups.map(({ name }) => ({ groupName: name })),
	mutationGroup: MUTATION_GROUP,
});
