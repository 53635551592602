import React from 'react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { TEAMS_PLATFORM_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import { TeamField } from '../../../ui/fields/team-field/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { singleUserPickerFieldWriter } from '../writers/index.tsx';

const TeamsPlatformCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<TeamField {...props} defaultValue={undefined} />
);

export const TeamsPlatformCustomFieldTypeConfig: FieldTypeConfig<TeamValue> = {
	icon: TeamsIcon,
	type: TEAMS_PLATFORM_CF_TYPE,
	Component: TeamsPlatformCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: singleUserPickerFieldWriter,
};
