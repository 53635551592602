import React from 'react';
import GoalIcon from '@atlaskit/icon/core/goal';
import RadioSelectField from '@atlassian/jira-issue-create-commons/src/ui/radio-select-field/index.tsx';
import type { Option } from '@atlassian/jira-issue-field-radio-select/src/ui/edit/types.tsx';
import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { radioSelectFieldWriter } from '../writers/index.tsx';

const RadioButtonsCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<RadioSelectField {...props} />
);

export const RadioButtonsCustomFieldTypeConfig: FieldTypeConfig<Option> = {
	icon: GoalIcon,
	type: RADIO_BUTTONS_CF_TYPE,
	Component: RadioButtonsCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: radioSelectFieldWriter,
};
