import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

const messages: {
	[key: string]: MessageDescriptor;
} = defineMessages({
	none: {
		id: 'issue-create-commons.components-field.none',
		defaultMessage: 'None',
		description: 'Placeholder text when no components passed to components field',
	},
	tooltipWhenDisabled: {
		id: 'issue-create-commons.components-field.tooltip-when-disabled',
		defaultMessage: "We couldn't find any components for this project",
		description: 'Tooltip showing why component field is disabled',
	},
});

export default messages;
