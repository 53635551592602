import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncRichTextEditEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jira-issue-field-rich-text-entry" */ './src/ui/edit').then(
			(module) => module.EntryPointContent,
		),
	),
	getPreloadProps: () => ({}),
});
