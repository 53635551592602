import { useCallback, useMemo, useState } from 'react';
import { stringify } from 'query-string';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SprintPayload } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import { MAX_ACTIVE_SPRINTS, MAX_RESULTS } from './constants.tsx';
import type { Props, UseSprintSuggestions } from './types.tsx';

const defaultParams = stringify({
	maxActiveSprints: MAX_ACTIVE_SPRINTS,
	maxResults: MAX_RESULTS,
});

export const getSprintSuggestionsFromServer = async (
	autoCompleteUrl: string,
	query: string,
	projectKey?: string,
) => {
	let queryString;
	if (projectKey !== null && projectKey !== undefined) {
		queryString = `${autoCompleteUrl}${encodeURIComponent(
			query,
		)}&projectKey=${encodeURIComponent(projectKey)}&${defaultParams}`;
	} else {
		queryString = `${autoCompleteUrl}${encodeURIComponent(query)}&${defaultParams}`;
	}

	return performGetRequest(queryString);
};

export const useSprintSuggestions: UseSprintSuggestions = (props: Props) => {
	const { getSuggestions = getSprintSuggestionsFromServer } = props || {};

	const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);

	const getSprintSuggestions = useCallback(
		async (autoCompleteUrl: string, query: string, projectKey?: string) => {
			try {
				const response: SprintPayload = await getSuggestions(autoCompleteUrl, query, projectKey);
				setIsLoadingSuggestions(false);
				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setIsLoadingSuggestions(false);

				throw error;
			}
		},
		[getSuggestions, setIsLoadingSuggestions],
	);

	const actions = useMemo(
		() => ({
			getSprintSuggestions,
			setIsLoadingSuggestions,
		}),
		[getSprintSuggestions, setIsLoadingSuggestions],
	);

	return [{ isLoadingSuggestions }, actions];
};
