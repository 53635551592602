import type { TeamFieldType } from '@atlassian/jira-issue-field-custom-team/src/common/types.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type { JiraTeamFieldInput, JiraTeam } from './types.tsx';

export const mutateTeamField = (
	selectedTeam: TeamFieldType,
	fieldId: string,
): JiraTeamFieldInput =>
	selectedTeam
		? {
				fieldId,
				mutationGroup: MUTATION_GROUP,
				team: {
					teamId: selectedTeam.value,
				},
			}
		: null;

export const transformDefaultTeam = (team: JiraTeam): TeamFieldType => ({
	label: team.name,
	value: team.teamId,
	isShared: true,
});
