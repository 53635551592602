import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import AddIcon from '@atlaskit/icon/glyph/add';
import { components } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type Props = {
	children: ReactNode;
	showFooter: boolean;
	projectKey: string;
	footerLabel?: string;
};

const MenuWithFooter = (props: Props) => {
	const { formatMessage } = useIntl();
	const { children, showFooter, projectKey, footerLabel, ...restProps } = props;
	return (
		<>
			{/* @ts-expect-error - TS2740 - Type '{ children: ReactNode; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more. */}
			<components.MenuList {...restProps}>{children}</components.MenuList>
			{showFooter && (
				<FooterWrapper>
					<FooterLinkWrapper>
						<FooterLink
							href={`/plugins/servlet/project-config/${projectKey}/administer-components`}
						>
							<AddIcon label="componentFooterAddIcon" size="small" />
							<LinkText>{footerLabel ?? formatMessage(messages.componentLabel)}</LinkText>
						</FooterLink>
					</FooterLinkWrapper>
				</FooterWrapper>
			)}
		</>
	);
};

export default MenuWithFooter;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkText = styled.span({
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FooterWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	padding: `${token('space.100', '8px')} 0px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border', colors.N30)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FooterLinkWrapper = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
	width: '100%',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FooterLink = styled.a({
	display: 'flex',
	alignItems: 'center',
	'&:hover': {
		textDecoration: 'none',
	},
});
