import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldsRemoved: {
		id: 'business-form.form-submission.fill-in-view.screenreader-announcer.fields-removed',
		defaultMessage: `{fieldCount, plural,
			one {# form field has been removed below}
			other {# form fields have been removed below}
		}`,
		description: 'Screenreader message for when form fields are removed from the form',
	},
	fieldsAdded: {
		id: 'business-form.form-submission.fill-in-view.screenreader-announcer.fields-added',
		defaultMessage: `{fieldCount, plural,
			one {# form field has been added below}
			other {# form fields have been added below}
		}`,
		description: 'Screenreader message for when form fields are added to the form',
	},
});
