import React from 'react';
import AttachmentIcon from '@atlaskit/icon/core/attachment';
import type { MediaFile } from '@atlaskit/media-picker';
import AttachmentsField from '@atlassian/jira-issue-create-commons/src/ui/attachments-field/index.tsx';
import { ATTACHMENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { AttachmentFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { attachmentFieldWriter } from '../writers/index.tsx';

const AttachmentFieldTypeComponent = (props: ProvidedFieldProps) =>
	props.mediaUploadContexts.attachmentUploadContext ? (
		<AttachmentsField
			{...props}
			mediaUploadContext={props.mediaUploadContexts.attachmentUploadContext}
		/>
	) : null;

export const AttachmentFieldTypeConfig: FieldTypeConfig<MediaFile[]> = {
	icon: AttachmentIcon,
	type: ATTACHMENT_TYPE,
	Component: AttachmentFieldTypeComponent,
	placeholder: <AttachmentFieldPlaceholder />,
	writer: attachmentFieldWriter,
};
