import React from 'react';
import InputField, {
	type FieldProps as Props,
} from '../../common/ui/fields/base-fields/input-field/index.tsx';

const UrlField = (props: Props) => {
	const { width = 350, ...rest } = props;
	return <InputField type="url" width={width} {...rest} />;
};

export default UrlField;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateUrlField } from './utils';
