import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

const messages: {
	[key: string]: MessageDescriptor;
} = defineMessages({
	datePickerPlaceholder: {
		id: 'issue-create-commons.date-time-field.date-picker-placeholder',
		defaultMessage: 'Select date',
		description: 'Placeholder text for date select in Date time picker field',
	},
	timePickerPlaceholder: {
		id: 'issue-create-commons.date-time-field.time-picker-placeholder',
		defaultMessage: 'Select time',
		description: 'Placeholder text for time select in Date time picker field',
	},
});

export default messages;
