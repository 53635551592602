import React from 'react';
import ClockIcon from '@atlaskit/icon/core/clock';
import DateField from '@atlassian/jira-issue-create-commons/src/ui/date-field/index.tsx';
import { DUE_DATE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DateFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { dateFieldWriter } from '../writers/index.tsx';

const DueDateFieldTypeComponent = (props: ProvidedFieldProps) => <DateField {...props} />;

export const DueDateFieldTypeConfig: FieldTypeConfig<string> = {
	icon: ClockIcon,
	type: DUE_DATE_TYPE,
	Component: DueDateFieldTypeComponent,
	placeholder: <DateFieldPlaceholder />,
	writer: dateFieldWriter,
};
