import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncDateTimeFieldEditViewEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jira-issue-field-date-time-edit-view" */ './index').then(
			(module) => module.DateTimeFieldEditViewEntryPointContent,
		),
	),
	getPreloadProps: () => ({}),
});
