import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

const messages: {
	[key: string]: MessageDescriptor;
} = defineMessages({
	placeholder: {
		id: 'issue-create-commons.date-field.placeholder',
		defaultMessage: 'Select date',
		description: 'Placeholder text for date field',
	},
	ariaLabel: {
		id: 'issue-create-commons.date-field.aria-label',
		defaultMessage: 'Enter a Date',
		description: 'Accessible name for the Date Picker Select',
	},
});

export default messages;
