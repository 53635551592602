export type BaseJiraBusinessForm = {
	formId: string;
	title: string;
	description: string;
	bannerColor?: string;
};

export const FormAccessLevelTypes = {
	LIMITED: 'limited',
	OPEN: 'open',
} as const;

export type FormAccessLevelTypes = (typeof FormAccessLevelTypes)[keyof typeof FormAccessLevelTypes];

export type InexactBaseJiraBusinessForm = BaseJiraBusinessForm;

export const AVAILABLE_FIELD_TYPE = Symbol('AVAILABLE_FIELD_TYPE');
export const FORM_FIELD_TYPE = Symbol('FORM_FIELD_TYPE');
export const DROPPABLE_SIDEBAR_AREA_TYPE = Symbol('DROPPABLE_SIDEBAR_AREA_TYPE');

export type DroppableSidebarAreaData = {
	type: typeof DROPPABLE_SIDEBAR_AREA_TYPE;
};

export type CreateFieldConfigProps = {
	mode: 'create' | 'edit';
	fieldId?: string;
} | null;

export type DraggableFieldType = typeof AVAILABLE_FIELD_TYPE | typeof FORM_FIELD_TYPE;

export type DraggableFieldData = {
	fieldId: string;
	type: DraggableFieldType;
	index: number;
	isLastField: boolean;
};

export type CardDragState =
	| {
			type: 'idle';
	  }
	| {
			type: 'dragging';
			container?: HTMLElement;
	  };

export type OnFieldDropType = (
	source: DraggableFieldData,
	destination: DraggableFieldData | DroppableSidebarAreaData,
) => void;
