import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type DescriptionReadViewType from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncDescriptionReadView = lazyForPaint<typeof DescriptionReadViewType>(
	() => import(/* webpackChunkName: "async-description-read-review" */ './main'),
	{
		ssr: false,
	},
);

export default AsyncDescriptionReadView;
