import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	menuHeader: {
		id: 'issue-field-custom-team.menu-header',
		defaultMessage: 'Pick a shared team that you’ve created through a Portfolio plan',
		description: 'Select menu header message. "Plan" means "Portfolio plan" here',
	},
	noMatches: {
		id: 'issue-field-custom-team.no-matches',
		defaultMessage: 'No Matches',
		description: 'Select menu message. Shows there is no issues found',
	},
	menuFooter: {
		id: 'issue-field-custom-team.menu-footer',
		defaultMessage: 'Showing 100 results. To display more results, please refine your search.',
		description:
			'Text indicates there are more search results and customer has to refine the text to display them.',
	},
});

export default messages;
