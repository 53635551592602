import type { MediaFile } from '@atlaskit/media-picker';
import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import type { File } from './types.tsx';

export const getFileIdsFromFiles = (files: MediaFile[]): string[] =>
	files.map((file) => file.id.toString());

export const validateAttachment = (
	value: File[] | null | null | undefined,
	isRequired: boolean,
) => {
	if (isRequired) {
		if (!value || value.length === 0) {
			return REQUIRED;
		}
	}
	return undefined;
};

export const getFieldDropZoneContainerID = (fieldId: string) =>
	`${fieldId ? `${fieldId}-` : ''}dropzone-container`;
