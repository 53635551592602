import type {
	Option,
	ParentChildOptionMap,
	SelectedOption,
	ParentChildOption,
} from '@atlassian/jira-issue-field-cascading-select/src/common/types.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type {
	CascadingSelectData,
	JiraOption,
	CascadingSelectDefaultData,
	JiraCascadingSelectFieldInput,
} from './types.tsx';

export const transformToSelectOption = (option: JiraOption): Option | null => {
	const { value, id } = option || {};
	if (value && id) {
		return { label: value, value: id };
	}
	return null;
};

export const transformToCascadingSelectOptions = (
	cascadingSelectData: CascadingSelectData,
): {
	allowedValues: Option[];
	allowedChildValues: ParentChildOptionMap;
} => {
	const allowedValues: Array<Option> = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const allowedChildValues: Record<string, any> = {};

	cascadingSelectData.forEach(({ parentOptionValue, childOptionValues }) => {
		const transformedParentOption = transformToSelectOption(parentOptionValue);
		if (parentOptionValue !== null && transformedParentOption !== null) {
			allowedValues.push(transformedParentOption);
			allowedChildValues[parentOptionValue.id] = childOptionValues.map(transformToSelectOption);
		}
	});

	return {
		allowedValues,
		allowedChildValues,
	};
};

export const transformDefaultValue = (defaultValue: CascadingSelectDefaultData): SelectedOption => {
	const { parentOptionValue, childOptionValue } = defaultValue;
	const transformedParentOption = transformToSelectOption(parentOptionValue);

	let transformedDefaultValue: SelectedOption = null;
	if (transformedParentOption !== null) {
		transformedDefaultValue = {
			...transformedParentOption,
			child: childOptionValue ? transformToSelectOption(childOptionValue) : undefined,
		};
	}
	return transformedDefaultValue;
};

export const mutateCascadingSelectField = (
	selectedOption: ParentChildOption | Option,
	fieldId: string,
): JiraCascadingSelectFieldInput => ({
	fieldId,
	parentOptionValue: {
		optionId: selectedOption.value,
	},
	// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
	childOptionValue: selectedOption.child
		? {
				// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
				optionId: selectedOption.child.value,
			}
		: null,
	mutationGroup: MUTATION_GROUP,
});
