import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	none: {
		id: 'issue-create-commons.multi-versions-field.none',
		defaultMessage: 'None',
		description: 'When there are no release versions',
	},
	tooltip: {
		id: 'issue-create-commons.multi-versions-field.tooltip',
		defaultMessage: "We couldn't find any versions for this project",
		description: 'when the version field is disabled',
	},
});
