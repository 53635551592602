import type { FormEvent } from 'react';

type SupportedElements = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEvent(event: any): event is FormEvent<SupportedElements> {
	return Boolean(event && event.target);
}

// From getTransform https://bitbucket.org/atlassian/atlassian-frontend-mirror/src/a68242c33728a09d2dfea8ce2eb763c7d867b550/design-system/form/src/field.tsx#lines-317
export function getValueFromEventOrValue<FieldValue>(
	eventOrValue: FormEvent<Element> | FieldValue,
): FieldValue | boolean | string | undefined {
	if (isEvent(eventOrValue)) {
		const { currentTarget } = eventOrValue;

		if (currentTarget.type === 'checkbox') {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if ((currentTarget as HTMLInputElement).checked) {
				return currentTarget.value || true;
			}

			return currentTarget.value ? undefined : false;
		}
		if (currentTarget) {
			return currentTarget.value;
		}
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return eventOrValue as FieldValue | boolean | string | undefined;
}
