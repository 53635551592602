import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addMoreGroupsPrompt: {
		id: 'issue-field-group-picker.edit.add-more-groups-prompt',
		defaultMessage: 'add more groups...',
		description:
			'Placeholder text that appears alongside currently selected groups to encourage the user to add more groups in a multi-group picker.',
	},
});
