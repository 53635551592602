import React from 'react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import CustomTeamsField from '@atlassian/jira-issue-create-commons/src/ui/custom-teams-field/index.tsx';
import type { TeamFieldType } from '@atlassian/jira-issue-field-custom-team/src/common/types.tsx';
import { TEAM_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { teamFieldWriter } from '../writers/index.tsx';

const TeamCustomFieldTypeComponent = (props: ProvidedFieldProps) => <CustomTeamsField {...props} />;

export const TeamCustomFieldTypeConfig: FieldTypeConfig<TeamFieldType> = {
	icon: TeamsIcon,
	type: TEAM_CF_TYPE,
	Component: TeamCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: teamFieldWriter,
};
