import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncCascadingSelectEditEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jira-issue-field-cascading-select-edit-entrypoint" */ './index'
		).then((module) => module.CascadingSelectViewEntryPointContent),
	),
	getPreloadProps: () => ({}),
});
