import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	released: {
		id: 'issue-field-versions.released',
		defaultMessage: 'Released',
		description: 'released versions',
	},
	unreleased: {
		id: 'issue-field-versions.unreleased',
		defaultMessage: 'Unreleased',
		description: 'unreleased versions',
	},
});

export default messages;
