import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	submissionErrorFlag: {
		id: 'business-form.form-submission.fill-in-view.submission-error-flag',
		defaultMessage: "Something's gone wrong",
		description: 'Error title for form submission error flag',
	},
	submissionAttachmentErrorContent: {
		id: 'business-form.form-submission.fill-in-view.submission-attachment-error-content',
		defaultMessage:
			'Attachment upload failed. Make sure the file format and size are correct, and check your internet connection before trying again.',
		description: 'Error message for form submission attachment error',
	},
	submitButton: {
		id: 'business-form.form-submission.fill-in-view.submit-button',
		defaultMessage: 'Submit',
		description: 'Button label for the submit action',
	},
});
