import { createContext, useContext } from 'react';
import type { FormsFeaturesContextProps } from './types.tsx';

const defaultFeaturesContext = {
	featureView: 'form-submit',
	shouldHidePoweredBy: false,
} as const;

const JWMFormsFeaturesContext = createContext<FormsFeaturesContextProps>(defaultFeaturesContext);

const useJWMFormsFeatures = () => useContext(JWMFormsFeaturesContext);

export default JWMFormsFeaturesContext;
export { useJWMFormsFeatures };
