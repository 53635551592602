import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import Button from '@atlaskit/button/new';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import type { UploadsStartEventPayload, MediaFile } from '@atlaskit/media-picker';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_SCROLL_OPTIONS } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import { AsyncAttachmentsFilmstripView } from '@atlassian/jira-issue-field-attachment/src/async.tsx';
import useAttachments from '@atlassian/jira-issue-field-attachment/src/services/use-attachments/index.tsx';
import type { TemporaryAttachment } from '@atlassian/jira-issue-field-attachment/src/services/use-attachments/types.tsx';
import AttachmentsUpload from '@atlassian/jira-issue-field-attachment/src/ui/attachments-upload/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import messages from './messages.tsx';
import type { Props, FieldValue, AttachmentFieldConfig } from './types.tsx';
import { validateAttachment, getFieldDropZoneContainerID } from './utils.tsx';

export const AttachmentsField = (props: Props) => {
	const {
		mediaUploadContext,
		onInProgressChange,
		projectId,
		fieldId = '',
		fieldProps: { onChange, value, ...fieldProps },
		maxAllowedTotalAttachmentsSize,
		onAttachmentSizeValidation,
		dropzoneContainerId = '',
		shouldScrollIntoViewOnUploadStart = false,
		defaultValue = [],
		attachmentCustomUploadURL,
	} = props;

	const { formatMessage } = useIntl();
	const {
		files,
		onUploadStart,
		onUploadError,
		mediaClientConfig,
		uploadConfig,
		onRemoveFile,
		onUploadEnd,
		isUploading,
		setAttachments,
		...resUseAttachments
	} = useAttachments({
		mediaUploadContext,
		projectId,
		maxAllowedTotalAttachmentsSize,
		onAttachmentSizeValidation,
		attachmentCustomUploadURL,
	});

	const { isDefaultValueInitialized, setDefaultValueInitialized } = resUseAttachments;

	useEffect(() => {
		// setting DefaultValueInitialized only when receiving default Value to guarantee we will set DefaultValue even if it's loaded with some delay.
		if (defaultValue && Array.isArray(defaultValue) && isDefaultValueInitialized === false) {
			const currentAttachments: TemporaryAttachment[] = [];
			setAttachments(
				currentAttachments.concat(
					defaultValue.map((mediaFile: MediaFile) => ({
						mediaFile,
						isUploading: false,
					})),
				),
			);
			setDefaultValueInitialized(true);
		}
	}, [setAttachments, defaultValue, isDefaultValueInitialized, setDefaultValueInitialized]);

	useEffect(() => {
		let shouldCallOnChange = false;
		const isValueAnArray = Array.isArray(value);

		if (isValueAnArray && value.length !== files.length) {
			shouldCallOnChange = true;
		}

		if (!isValueAnArray && files.length) {
			shouldCallOnChange = true;
		}

		if (shouldCallOnChange) {
			onChange(files);
		}
	}, [files, onChange, value]);

	// TBD: create a controlled reusable attachment component
	useEffect(() => {
		// @ts-expect-error - TS2339 - Property 'shouldClear' does not exist on type 'FieldValue'.
		if (value && value.shouldClear === true) {
			setAttachments([]);
		}
	}, [setAttachments, value]);

	useEffect(() => {
		onInProgressChange && onInProgressChange(isUploading);
	}, [isUploading, onInProgressChange]);

	if (!mediaUploadContext) {
		return null;
	}

	const onAttachmentUploadStart = (uploadStartPayload: UploadsStartEventPayload) => {
		onUploadStart(uploadStartPayload);
		if (shouldScrollIntoViewOnUploadStart) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const fieldElement = document.getElementById(getFieldDropZoneContainerID(fieldId));
			if (fieldElement) {
				scrollIntoViewIfNeeded(fieldElement, DEFAULT_SCROLL_OPTIONS);
			}
		}
	};

	return (
		<>
			<FieldDropzoneContainer id={getFieldDropZoneContainerID(fieldId)}>
				<BrowseDropzoneArea>
					<UploadIcon label="Upload" primaryColor={token('color.icon', colors.N100)} />
					<BrowseDropzoneAreaText>
						{`${formatMessage(messages.dropzone)}`}&nbsp;
					</BrowseDropzoneAreaText>
					<AttachmentsUpload
						{...fieldProps}
						mediaClientConfig={mediaClientConfig}
						uploadConfig={uploadConfig}
						onUploadError={onUploadError}
						onUploadStart={onAttachmentUploadStart}
						onUploadEnd={onUploadEnd}
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						dropzoneContainer={document.getElementById(
							dropzoneContainerId || getFieldDropZoneContainerID(fieldId),
						)}
					>
						{({ setIsBrowserOpen }) => (
							<Box xcss={browseButtonStyles}>
								<Button
									onClick={() => setIsBrowserOpen(true)}
									aria-label={formatMessage(messages.browseAriaLabel)}
								>
									{formatMessage(messages.browse)}
								</Button>
							</Box>
						)}
					</AttachmentsUpload>
				</BrowseDropzoneArea>
			</FieldDropzoneContainer>
			{files.length > 0 && (
				<Box xcss={mediaContainerStyles}>
					<Placeholder name="attachments-filmstrip-view" fallback={null}>
						<AsyncAttachmentsFilmstripView
							mediaClientConfig={mediaClientConfig}
							files={files}
							onRemoveFile={onRemoveFile}
							collectionName={mediaUploadContext.collection}
						/>
					</Placeholder>
				</Box>
			)}
		</>
	);
};

export default withFormField({
	validator: validateAttachment,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element | null' is not assignable to parameter of type 'AbstractComponent<ComponentProps<AttachmentFieldConfig, FieldValue>, any>'.
})<AttachmentFieldConfig, FieldValue>(AttachmentsField);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldDropzoneContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	border: `${(gridSize * 1) / 8}px dashed ${token('color.border', colors.N50)}`,
	padding: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
});

const mediaContainerStyles = xcss({
	marginTop: 'space.050',
});

const browseButtonStyles = xcss({
	marginLeft: 'space.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BrowseDropzoneArea = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BrowseDropzoneAreaText = styled.span({
	marginLeft: token('space.050', '4px'),
});
