import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	TeamFieldType,
	ExternalTeam,
	AutoCompleteResponse,
	GroupOption,
} from '../../common/types.tsx';

export const transformToTeamFieldType = (team: ExternalTeam): TeamFieldType => ({
	label: team.title,
	value: team.id.toString(),
	isShared: team.shareable,
	avatarUrl: team.avatarUrl,
});

export const transformTeamFieldTypeString = (team: TeamFieldType): string | null => {
	if (team) {
		return team.value.toString();
	}
	return null;
};

export const fetchTeams = async (
	query: string,
	autoCompleteUrl: string,
): Promise<GroupOption[]> => {
	const response: AutoCompleteResponse | null = await performPostRequest(autoCompleteUrl, {
		body: JSON.stringify({
			excludedIds: [],
			maxResults: 100,
			query,
		}),
	});
	if (response) {
		const teams = response.teams.map(transformToTeamFieldType);
		return [
			{
				moreResultsAvailable: response.moreResultsAvailable,
				options: teams,
			},
		];
	}
	return [];
};
