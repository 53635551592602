import React from 'react';
import ReleaseIcon from '@atlaskit/icon/core/release';
import MultiVersionsField from '@atlassian/jira-issue-create-commons/src/ui/multi-versions-field/index.tsx';
import SingleVersionField from '@atlassian/jira-issue-create-commons/src/ui/single-version-field/index.tsx';
import type {
	VersionOption,
	VersionOptionExternal,
	Status,
} from '@atlassian/jira-issue-field-versions/src/common/types.tsx';
import {
	type FIX_VERSIONS_TYPE,
	type AFFECTS_VERSIONS_TYPE,
	type MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders/index.tsx';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types.tsx';
import { multiSelectFieldWriter, selectFieldWriter } from '../writers/index.tsx';

// Structure of `allowedValues` options from field meta API response
type AllowedValues = {
	id: string;
	name: string;
	released: boolean;
	archived: boolean;
}[];

// The version field components expect `allowedValues` options to have a `status` enum field value
function mapPropToExternalOptionAllowedValues(
	allowedValuesProp: AllowedValues,
): VersionOptionExternal[] {
	return allowedValuesProp.map<VersionOptionExternal>(({ id, name, released, archived }) => {
		let status: Status = 'UNRELEASED';
		if (archived) {
			status = 'ARCHIVED';
		} else if (released) {
			status = 'RELEASED';
		}

		return {
			id,
			name,
			status,
		};
	});
}

const VersionFieldTypeComponent = (props: ProvidedFieldProps<undefined, AllowedValues>) => {
	const allowedValues = mapPropToExternalOptionAllowedValues(props.allowedValues);

	return <SingleVersionField {...props} allowedValues={allowedValues} />;
};

export const VersionFieldTypeConfig: FieldTypeConfig<
	VersionOption | null,
	undefined,
	AllowedValues
> = {
	icon: ReleaseIcon,
	type: VERSION_CF_TYPE,
	Component: VersionFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};

type MultiVersionFieldType =
	| typeof FIX_VERSIONS_TYPE
	| typeof AFFECTS_VERSIONS_TYPE
	| typeof MULTI_VERSION_CF_TYPE;

const MultiVersionsFieldTypeComponent = (props: ProvidedFieldProps<undefined, AllowedValues>) => {
	const allowedValues = mapPropToExternalOptionAllowedValues(props.allowedValues);

	return <MultiVersionsField {...props} allowedValues={allowedValues} />;
};

export const MultiVersionsFieldTypeConfig: (
	type: MultiVersionFieldType,
) => FieldTypeConfig<VersionOption[], undefined, AllowedValues> = (type) => ({
	icon: ReleaseIcon,
	type,
	Component: MultiVersionsFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: multiSelectFieldWriter,
});
